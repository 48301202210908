#contact-form {

  .gfield_label {
    font-size: 1.7em;
    margin-bottom: 5px;
    font-weight: 400;
  }

  .gfield_required {
    font-size: 18px;
  }

  .gform_footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    input[type="submit"] {
      width: 100%;
      @media screen and (min-width: 767px) {
        width: 150px;
      }
    }
  }
}
