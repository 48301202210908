@media only screen and (min-width: 600px) {
	
	.events-index {

		.event {
			@include clearfix();

			.event-logo {
				float: left;
				margin-right: 15px;
				width: 25%;

				.attachment-post-thumbnail {
					width: 100%;
				}
			}

			.event-details {
				float: left;
				width: 70%;
			}

			.event-description span{
				display: inline;
			}
		}
	}

}

