.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-40 {
  margin-bottom: 40px; }

.landing-flexible {
  padding: 0 !important; }

@-webkit-keyframes showslide {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes showslide {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes showslide {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@font-face {
  font-family: 'icomoon';
  src: url("../../fonts/icomoon.eot?baz3y5");
  src: url("../../fonts/icomoon.eot?#iefixbaz3y5") format("embedded-opentype"), url("../../fonts/icomoon.woff?baz3y5") format("woff"), url("../../fonts/icomoon.ttf?baz3y5") format("truetype"), url("../../fonts/icomoon.svg?baz3y5#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-collateral:before {
  content: "\e603"; }

.icon-csr:before {
  content: "\e604"; }

.icon-events:before {
  content: "\e605"; }

.icon-pencil:before {
  content: "\e606"; }

.icon-in:before {
  content: "\e600"; }

.icon-search:before {
  content: "\e601"; }

.icon-twitter:before {
  content: "\e602"; }

/*
Base mobile-first styles and small screens
*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none; }

body {
  background: #FFFFFF;
  font-family: 'Lato', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 62.5%; }

h1, h2, h3, h4, h5, h6 {
  color: #464646; }

a {
  color: #0a0a2c;
  text-decoration: none;
  font-weight: 700; }
  a:hover {
    text-decoration: underline; }

.clearfix {
  clear: both; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

#wrapper {
  background: #FFFFFF;
  min-width: 320px;
  min-height: 100%;
  position: absolute;
  width: 100%;
  z-index: 450;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  #wrapper.menu-open {
    -webkit-transform: translate(-250px, 0);
    transform: translate(-250px, 0);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }

#main-header {
  border-bottom: 1px solid #d9d9d9;
  position: relative; }
  #main-header .inner {
    padding: 25px 15px; }
  #main-header .logo {
    width: 203px;
    height: auto; }
  #main-header .lines-button {
    display: inline-block;
    padding: 1rem 0.5rem;
    -webkit-transition: .3s;
    transition: .3s;
    cursor: pointer;
    user-select: none;
    border-radius: 0.28571rem; }
    #main-header .lines-button:hover {
      opacity: 1; }
    #main-header .lines-button:active {
      -webkit-transition: 0;
      transition: 0;
      background: rgba(0, 0, 0, 0.1); }
  #main-header .lines {
    display: inline-block;
    width: 2rem;
    height: 0.28571rem;
    background: #e6173e;
    border-radius: 0.14286rem;
    transition: 0.2s;
    position: relative;
    /*create the upper and lower lines as pseudo-elements of the middle line*/ }
    #main-header .lines:before, #main-header .lines:after {
      display: inline-block;
      width: 2rem;
      height: 0.28571rem;
      background: #e6173e;
      border-radius: 0.14286rem;
      transition: 0.2s;
      position: absolute;
      left: 0;
      content: '';
      -webkit-transform-origin: 0.14286rem center;
      transform-origin: 0.14286rem center; }
    #main-header .lines:before {
      top: 0.5rem; }
    #main-header .lines:after {
      top: -0.5rem; }
  #main-header .lines-button.x2 .lines {
    -webkit-transition: background .3s .1s ease;
    transition: background .3s .1s ease; }
    #main-header .lines-button.x2 .lines:before, #main-header .lines-button.x2 .lines:after {
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-transition: top .3s .2s ease, transform .3s ease;
      transition: top .3s .2s ease, transform .3s ease; }
  #main-header .lines-button.x2.close .lines {
    -webkit-transition: background .3s 0s ease;
    transition: background .3s 0s ease;
    background: transparent; }
    #main-header .lines-button.x2.close .lines:before, #main-header .lines-button.x2.close .lines:after {
      -webkit-transition: top .3s ease, transform .3s .3s ease;
      transition: top .3s ease, transform .3s .3s ease;
      top: 0;
      width: 2rem; }
    #main-header .lines-button.x2.close .lines:before {
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg); }
    #main-header .lines-button.x2.close .lines:after {
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg); }
  #main-header #mobile-menu-button {
    background: transparent;
    border: none;
    position: absolute;
    top: 25px;
    right: 15px; }
    #main-header #mobile-menu-button:active, #main-header #mobile-menu-button:focus {
      outline: none; }

.menu-social {
  list-style-type: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto; }
  .menu-social li {
    display: block;
    float: left;
    width: 33%; }
    .menu-social li a {
      color: #d12e3d;
      display: block;
      font-size: 1.4em;
      text-decoration: none;
      text-align: center; }
      .menu-social li a i {
        clear: both;
        display: block; }

#mobile-menu {
  background: #d12e3d;
  position: fixed;
  right: 0;
  width: 250px;
  height: 100%;
  z-index: 400; }
  #mobile-menu .menu {
    list-style-type: none;
    padding: 0 15px; }
    #mobile-menu .menu li a {
      border-bottom: 1px solid #ec516e;
      color: #FFFFFF;
      display: block;
      font-size: 2.1em;
      text-decoration: none;
      padding: 15px 0; }
    #mobile-menu .menu .sub-menu {
      display: none; }
  #mobile-menu .menu-social {
    margin-top: 25px;
    position: static;
    width: 100%; }
    #mobile-menu .menu-social li a {
      color: #FFFFFF;
      font-size: 1.6em; }
      #mobile-menu .menu-social li a i {
        font-size: 2.3em;
        margin-bottom: 10px; }

#main-menu-container {
  display: none;
  position: relative; }

#content .inner:after {
  content: "";
  display: table;
  clear: both; }

#content.has-padding {
  padding: 15px; }

#content.page-not-found .inner {
  min-height: 500px; }
  #content.page-not-found .inner h1.main-title {
    font-size: 3.5em; }

.page-content h1 {
  color: #d61226;
  font-size: 2.7em;
  font-weight: 600;
  margin-bottom: 15px; }

.page-content h2 {
  color: #23185d;
  font-size: 2.2em;
  font-weight: 600;
  margin-bottom: 15px; }

.page-content p {
  color: #464646;
  font-size: 1.7em;
  line-height: 24px;
  margin-bottom: 15px; }
  .page-content p:last-child {
    margin-bottom: 0; }

.page-content ul, .page-content ol {
  color: #464646;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 15px;
  padding-left: 30px; }
  .page-content ul li, .page-content ol li {
    margin-bottom: 10px; }
  .page-content ul ul, .page-content ul ol, .page-content ol ul, .page-content ol ol {
    margin-top: 10px; }

.page-content ul {
  list-style-type: disc; }

.page-content ol {
  list-style-type: decimal; }

.page-content .alignnone, .page-content .alignleft, .page-content .aligncenter, .page-content .alignright {
  max-width: 100%;
  height: auto; }

.page-content strong {
  font-weight: 600; }

.page-section .inner {
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-position: 50% 60px;
  background-size: 280px auto;
  -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
  padding: 20px 15px;
  margin: 15px; }

.page-section .section-feature-image {
  display: block;
  margin: 0 auto 25px auto;
  max-width: 400px;
  width: 100%;
  height: auto; }

.page-section h1 {
  color: #d61226;
  font-size: 2.7em;
  font-weight: 600;
  margin-bottom: 25px; }

.page-section h2 {
  color: #23185d;
  font-size: 2.2em;
  font-weight: 600;
  margin-bottom: 25px; }

.page-section.has-feature-image h1, .page-section.has-feature-image h2 {
  height: auto; }

.page-section p {
  color: #464646;
  font-size: 1.7em;
  line-height: 24px;
  margin-bottom: 25px; }
  .page-section p:last-child {
    margin-bottom: 0; }

.page-section ul, .page-section ol {
  border-left: 2px solid #a7a3be;
  color: #464646;
  font-size: 1.7em;
  line-height: 24px;
  padding: 0 0 0 30px;
  margin-bottom: 25px; }

.page-section ul, .page-section ul li {
  list-style-type: disc;
  font-style: italic; }

.page-large-section .inner {
  padding: 15px; }

.subpage-menu {
  border-top: 1px solid #ebebeb;
  list-style-type: none; }
  .subpage-menu li a {
    background: url("../../img/arrow-right.png") no-repeat 15px 50%;
    background-size: 8px 12px;
    -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
    color: #898989;
    display: block;
    font-size: 1.7em;
    font-weight: 300;
    padding: 10px 15px 10px 35px; }
  .subpage-menu li:first-child a {
    padding-top: 20px;
    background-position: 15px 65%; }
  .subpage-menu li:last-child a {
    padding-bottom: 20px;
    background-position: 15px 37%; }
  .subpage-menu li.current-menu-item a {
    color: #080b2a;
    font-weight: 600; }

.subpage-menu-link {
  background: url("../../img/arrow-down.png") no-repeat 95% 50%;
  background-size: 13px 9px;
  -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
  border-bottom: 1px solid #d9d9d9;
  color: #464646;
  display: block;
  font-size: 1.7em;
  padding: 15px; }

.subpage-menu-container-desktop {
  display: none; }

.button {
  background: #d12e3d;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  font-size: 1.6em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  padding: 20px;
  margin-top: 25px;
  transition: background 0.2s linear; }
  .button:hover, .button:focus, .button:active {
    background-color: #a72531;
    text-decoration: none;
    outline: none;
    transition: background 0.2s linear; }

.share {
  font-weight: 600; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .video-wrapper > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.home #featured-images {
  display: table;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 320px;
  height: 275px; }
  .home #featured-images .inner {
    display: table-row; }
  .home #featured-images .col1 {
    display: none; }
  .home #featured-images .main-col {
    display: table-cell;
    font-size: 0;
    line-height: 0;
    position: relative;
    vertical-align: top;
    width: 100%; }
    .home #featured-images .main-col img {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      max-height: 524px !important;
      z-index: 100; }
      .home #featured-images .main-col img.active {
        opacity: 1;
        z-index: 200; }
      .home #featured-images .main-col img.show {
        -webkit-animation: showslide 1.5s ease-in-out;
        -webkit-animation-fill-mode: forwards;
        -moz-animation: showslide 1.5s ease-in-out;
        -moz-animation-fill-mode: forwards;
        animation: showslide 1.5s ease-in-out;
        animation-fill-mode: forwards;
        z-index: 300; }
  .home #featured-images .col2 {
    display: none; }

.home #consumer-video-wrapper {
  margin-top: 40px; }
  .home #consumer-video-wrapper .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

.home .page-content h1 {
  font-size: 2.7em;
  line-height: 36px; }

.page-template-about-us-php #find-out-more {
  background: #bfc1d8; }
  .page-template-about-us-php #find-out-more .inner {
    padding: 15px; }
  .page-template-about-us-php #find-out-more h2 {
    color: #FFFFFF;
    font-size: 2.7em;
    font-weight: 300;
    margin-bottom: 30px; }
  .page-template-about-us-php #find-out-more aside {
    border: 1px solid #e1e1e1;
    text-align: center;
    margin: 0 0 25px 0;
    padding: 15px; }
    .page-template-about-us-php #find-out-more aside:last-child {
      margin-bottom: 0; }
    .page-template-about-us-php #find-out-more aside h3 {
      color: #080b2a;
      font-size: 2.4em;
      font-weight: 300;
      margin: 0 0 20px 0; }
      .page-template-about-us-php #find-out-more aside h3 i {
        color: #717191;
        margin-right: 5px; }
    .page-template-about-us-php #find-out-more aside p {
      color: #FFFFFF;
      font-size: 1.7em;
      font-weight: 300;
      line-height: 24px;
      margin: 0 0 20px 0; }

.page-template-leadership-team-php #leadership-intro .attachment-post-thumbnail {
  margin-top: 25px;
  max-width: 100%;
  height: auto; }

.page-template-leadership-team-php #leadership-intro blockquote {
  font-style: italic; }
  .page-template-leadership-team-php #leadership-intro blockquote cite {
    display: block;
    font-style: normal;
    font-weight: 600;
    text-align: right; }

.page-template-leadership-team-php #leadership-people {
  margin-top: 30px; }
  .page-template-leadership-team-php #leadership-people aside {
    margin-bottom: 30px; }
    .page-template-leadership-team-php #leadership-people aside h2 {
      color: #23185d;
      font-size: 2.2em; }
    .page-template-leadership-team-php #leadership-people aside p {
      color: #464646; }

.page-template-leadership-team-php .backers-heading {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAACCAIAAAAfCIEKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowNDcxOTM3NC0wMmJmLTRjMmYtOTQwMy05OTkyMGQzZjRmZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTgxRTQwNUE2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTgxRTQwNTk2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxODE3NzBkZC1jZTExLTRiOTktOTYwZS1kNTE0ZTdhMmIwMWIiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1NWI5NTU5NS05OWE2LTExNzctOGZjYy1hZjkzYTQ1ZDQ3NDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5jBSweAAAAF0lEQVR42mI8f+EOAxJgYWJiROYDBBgASyQChkOKeO4AAAAASUVORK5CYII=) repeat-x 0 55%;
  background-size: auto 1px;
  -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
  margin-top: 25px;
  margin-bottom: 10px; }
  .page-template-leadership-team-php .backers-heading .text {
    background: #FFFFFF;
    display: inline-block;
    padding-right: 20px; }

.page-template-leadership-team-php .page-section .section-feature-image {
  border: 1px solid #e1e1e1; }

.page-template-what-we-do-php #areas-figure {
  margin: 25px 0 0 0; }
  .page-template-what-we-do-php #areas-figure img {
    display: block;
    margin: 0 auto 5px auto;
    max-width: 100%;
    height: auto; }
    .page-template-what-we-do-php #areas-figure img:last-child {
      margin-bottom: 0; }

.page-template-what-we-do-php #working-with-tiles {
  margin-top: 25px; }
  .page-template-what-we-do-php #working-with-tiles .tile {
    margin-bottom: 25px; }
    .page-template-what-we-do-php #working-with-tiles .tile:after {
      content: "";
      display: table;
      clear: both; }
    .page-template-what-we-do-php #working-with-tiles .tile:last-child {
      margin-bottom: 0; }
    .page-template-what-we-do-php #working-with-tiles .tile .tile-image {
      background-color: #d61226;
      background-repeat: no-repeat;
      background-position: 50% 30%;
      -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
      color: #FFFFFF;
      display: block;
      font-size: 2.3em;
      text-align: center;
      float: left;
      margin-right: 15px;
      padding-top: 90px;
      width: 130px;
      height: 130px; }
    .page-template-what-we-do-php #working-with-tiles .tile p {
      color: #464646;
      font-size: 1.6em;
      line-height: 24px;
      margin: -5px 0 0 0; }

@media only screen and (max-width: 350px) {
  .page-template-what-we-do-php #working-with-tiles .tile p {
    float: left !important;
    width: 50% !important; } }

@media (max-width: 940px) {
  .page-template-sectors-php .page-section.section-1 h2 {
    min-height: 150px; } }

@media (max-width: 940px) {
  .page-template-sectors-php .page-section.section-2 h2 {
    min-height: 180px; } }

@media (max-width: 940px) {
  .page-template-sectors-php .page-section h2 {
    height: 180px; } }

.page-template-sectors-php .sectors-chart {
  display: block;
  margin: 30px auto;
  max-width: 100%;
  height: auto; }

.page-template-careers-php #careers-feature:after {
  content: "";
  display: table;
  clear: both; }

.page-template-careers-php #careers-feature .inner {
  background: #ebebeb;
  margin: 15px;
  padding: 15px; }

.page-template-careers-php #careers-feature h2 {
  color: #d61226;
  font-size: 2.7em;
  font-weight: 600; }

.page-template-careers-php #careers-feature .flexslider {
  background: transparent;
  border: none;
  box-shadow: none;
  margin: 15px 0 0 0; }

.page-template-careers-php #careers-feature .flex-direction-nav a {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: none; }

.page-template-careers-php #careers-feature .flex-direction-nav .flex-prev {
  left: 20px; }

.page-template-careers-php #careers-feature .flex-direction-nav .flex-next {
  right: 20px; }

.page-template-careers-php #gallery-content #viewer {
  background: url("../../img/loading.gif") no-repeat 50% 50%;
  padding: 0 15px;
  margin-bottom: 15px; }
  .page-template-careers-php #gallery-content #viewer img {
    max-width: 100%;
    height: auto; }
  .page-template-careers-php #gallery-content #viewer img, .page-template-careers-php #gallery-content #viewer div {
    opacity: 1;
    transition: opacity 0.3s ease-in-out; }
  .page-template-careers-php #gallery-content #viewer img.hide, .page-template-careers-php #gallery-content #viewer div.hide {
    opacity: 0;
    transition: opacity 0.1s linear; }

.page-template-careers-php #gallery-content .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .page-template-careers-php #gallery-content .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.page-template-careers-php #gallery-content h2 {
  color: #691c33;
  padding: 0 15px;
  margin: 0 0 10px 0; }

.page-template-careers-php .thumbnail-strip {
  margin-bottom: 25px;
  position: relative;
  width: 100%;
  height: 85px; }
  .page-template-careers-php .thumbnail-strip .inner {
    position: relative;
    width: 100%;
    height: 85px; }
  .page-template-careers-php .thumbnail-strip .fade-left, .page-template-careers-php .thumbnail-strip .fade-right {
    background-size: 30px auto;
    -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
    background-repeat: repeat-y;
    position: absolute;
    top: 0;
    width: 30px;
    height: 85px;
    z-index: 500; }
  .page-template-careers-php .thumbnail-strip .fade-left {
    background-image: url("../../img/fade-left.png");
    left: 0; }
  .page-template-careers-php .thumbnail-strip .fade-right {
    background-image: url("../../img/fade-right.png");
    right: 0; }
  .page-template-careers-php .thumbnail-strip .scroll-left, .page-template-careers-php .thumbnail-strip .scroll-right {
    display: none; }
  .page-template-careers-php .thumbnail-strip ul {
    list-style-type: none;
    position: absolute;
    padding: 0 15px;
    z-index: 100;
    height: 85px; }
    .page-template-careers-php .thumbnail-strip ul:after {
      content: "";
      display: table;
      clear: both; }
    .page-template-careers-php .thumbnail-strip ul li {
      float: left;
      margin-right: 15px;
      margin-bottom: 15px; }
      .page-template-careers-php .thumbnail-strip ul li:last-child {
        margin-right: 0; }
      .page-template-careers-php .thumbnail-strip ul li.active img {
        border: 2px solid #d12e3d; }
      .page-template-careers-php .thumbnail-strip ul li img {
        border: 2px solid #ebebeb;
        transition: border 0.2s linear;
        width: 120px;
        height: auto; }

.page-template-careers-php .overthrow-enabled .thumbnail-strip {
  position: relative;
  overflow: hidden;
  height: 85px; }
  .page-template-careers-php .overthrow-enabled .thumbnail-strip .inner {
    position: relative;
    overflow: auto;
    height: 85px; }
  .page-template-careers-php .overthrow-enabled .thumbnail-strip ul {
    position: absolute;
    height: 85px; }
    .page-template-careers-php .overthrow-enabled .thumbnail-strip ul li {
      margin-bottom: 0; }

.page-template-careers-php .featured-testimonial {
  padding: 0 15px; }
  .page-template-careers-php .featured-testimonial .testimonial-picture {
    float: left;
    margin: 5px 15px 5px 0;
    max-width: 45%; }
  .page-template-careers-php .featured-testimonial .testimonial {
    color: #898989;
    font-size: 2.2em;
    font-weight: 300;
    font-style: italic;
    line-height: 29px; }
    .page-template-careers-php .featured-testimonial .testimonial cite {
      color: #464646;
      clear: both;
      display: block;
      font-style: normal;
      text-align: right;
      margin-top: 10px; }

.page-template-careers-php #careers-testimonials .inner {
  padding: 15px; }

.page-template-careers-php #careers-testimonials .testimonial {
  margin-bottom: 30px; }
  .page-template-careers-php #careers-testimonials .testimonial:last-child {
    margin-bottom: 0; }

.page-template-careers-php #careers-testimonials .testimonial-picture {
  margin: 0 0 15px 0; }

.page-template-careers-php #careers-testimonials .testimonial-detail {
  color: #898989;
  font-size: 1.7em;
  font-weight: 300;
  font-style: italic;
  line-height: 24px; }
  .page-template-careers-php #careers-testimonials .testimonial-detail cite {
    color: #464646;
    display: block;
    font-style: normal;
    margin-top: 10px; }

.single-job #apply-form {
  background: #ebebeb; }
  .single-job #apply-form .inner {
    padding: 15px; }
  .single-job #apply-form h2 {
    color: #464646;
    font-size: 3.1em;
    margin-bottom: 15px; }
  .single-job #apply-form p {
    color: #898989;
    font-size: 1.7em;
    line-height: 24px;
    margin: 0 0 25px 0; }
  .single-job #apply-form p.error {
    color: #f03546;
    font-size: 1.4em;
    margin-bottom: -23px; }
  .single-job #apply-form #txt_attachment_wrapper p.error {
    margin-bottom: 0; }
  .single-job #apply-form .field_wrapper.error input {
    border-color: #f03546; }
  .single-job #apply-form .success {
    color: #31b509; }

form p.error {
  color: #f03546;
  font-size: 1.5em;
  margin: 8px 0 10px 0; }

form .req,
form .gfield_required {
  color: #d12f3e;
  cursor: help;
  font-size: 130%;
  padding: 0 5px; }

form li,
form .field_wrapper {
  margin: 0 0 15px 0; }
  form li label,
  form .field_wrapper label {
    display: block;
    font-size: 1.7em;
    margin-bottom: 5px; }
  form li input:not([type=checkbox]):not([type=radio]), form li textarea,
  form .field_wrapper input:not([type=checkbox]):not([type=radio]),
  form .field_wrapper textarea {
    color: #464646;
    font-family: 'Lato', 'Arial', sans-serif;
    font-size: 1.5em;
    padding: 10px;
    width: 100%; }
    form li input:not([type=checkbox]):not([type=radio]):hover, form li input:not([type=checkbox]):not([type=radio]):focus, form li input:not([type=checkbox]):not([type=radio]):active, form li textarea:hover, form li textarea:focus, form li textarea:active,
    form .field_wrapper input:not([type=checkbox]):not([type=radio]):hover,
    form .field_wrapper input:not([type=checkbox]):not([type=radio]):focus,
    form .field_wrapper input:not([type=checkbox]):not([type=radio]):active,
    form .field_wrapper textarea:hover,
    form .field_wrapper textarea:focus,
    form .field_wrapper textarea:active {
      border-color: #0a0a0a;
      outline: none; }
    form li input:not([type=checkbox]):not([type=radio])[type='file'], form li textarea[type='file'],
    form .field_wrapper input:not([type=checkbox]):not([type=radio])[type='file'],
    form .field_wrapper textarea[type='file'] {
      border: none; }

form .form_submit {
  background: #d12e3d;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  display: block;
  font-family: 'Lato', 'Arial', sans-serif;
  font-size: 1.6em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 25px;
  transition: background 0.2s linear;
  width: 100%; }
  form .form_submit:hover, form .form_submit:focus, form .form_submit:active {
    background-color: #a72531;
    outline: none;
    text-decoration: none;
    transition: background 0.2s linear; }

.success,
.gform_confirmation_message {
  color: #31b509;
  font-size: 17px;
  margin: 0; }

.page-template-media-index-php .page-section {
  -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
  background-position: 50% 0; }
  .page-template-media-index-php .page-section .section-feature-image {
    margin-left: auto;
    margin-right: auto;
    max-height: 180px;
    max-width: 100%;
    width: auto; }

.media-index-intro {
  margin-bottom: 15px; }
  .media-index-intro .inner {
    padding: 25px 15px; }
  .media-index-intro h1 {
    color: #d61226;
    font-size: 2.7em;
    font-weight: 600;
    line-height: 32px; }
  .media-index-intro p {
    color: #898989;
    font-size: 1.7em;
    line-height: 24px;
    margin-top: 15px; }
  .media-index-intro .attachment-full {
    margin-top: 15px;
    max-width: 100%;
    height: auto; }

.in-the-media-intro {
  font-size: 0;
  text-align: center;
  display: block;
  margin-bottom: 20px; }
  .in-the-media-intro .inner {
    background: #ebebeb;
    padding: 30px 0; }
  .in-the-media-intro .logo1 {
    width: 285px; }
  .in-the-media-intro .logo2 {
    width: 160px; }
  .in-the-media-intro .logo3 {
    width: 230px; }
  .in-the-media-intro .logo4 {
    width: 265px; }

.tabs {
  border-bottom: 1px solid #ebebeb;
  list-style-type: none;
  margin-top: 25px; }
  .tabs:after {
    content: "";
    display: table;
    clear: both; }
  .tabs li {
    float: left; }
    .tabs li a {
      border-bottom: 3px solid transparent;
      display: inline-block;
      color: #0a0a2c;
      font-size: 1.1em;
      margin-right: 10px;
      padding: 10px 0; }
      .tabs li a a:hover {
        text-decoration: none; }
    .tabs li:last-child a {
      margin-right: 0; }
    .tabs li.active a {
      border-bottom: 3px solid #e6173e;
      color: #464646; }

.media-index-list:after {
  content: "";
  display: table;
  clear: both; }

.media-index-list > .inner {
  max-width: 100%;
  width: 100%; }
  .media-index-list > .inner:after {
    content: "";
    display: table;
    clear: both; }

.media-index-list .media-post .inner {
  border-bottom: 1px solid #ebebeb;
  margin: 0 15px;
  padding: 25px 0;
  max-width: 1280px; }

.media-index-list .media-post .attachment-post-thumbnail, .media-index-list .media-post .attachment-large {
  border: 1px solid #e9e9e9;
  display: block;
  margin: 0 auto 25px auto;
  max-width: 100%;
  height: auto; }

.media-index-list .media-post .post-title {
  font-size: 2.4em;
  line-height: 27px;
  margin-bottom: 10px; }
  .media-index-list .media-post .post-title a {
    color: #e6173e; }

.media-index-list .media-post .post-date {
  color: #464646;
  display: block;
  font-size: 1.7em;
  font-style: italic;
  margin-bottom: 20px; }

.media-index-list .media-post .post-excerpt p {
  color: #898989;
  font-size: 1.7em;
  line-height: 24px; }

.media-index-list .media-post .post-excerpt .button {
  margin-top: 25px; }

.media-index-list .media-post.press-release.first-post .inner, .media-index-list .media-post.post.first-post .inner {
  background: #ebebeb;
  padding-left: 25px;
  padding-right: 25px; }

.media-index-list.jobs-list .inner {
  padding: 0 15px; }

.media-index-list.jobs-list .job {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding-bottom: 15px; }

.media-index-list .navigation {
  text-align: center;
  margin-bottom: 25px; }
  .media-index-list .navigation:after {
    content: "";
    display: table;
    clear: both; }
  .media-index-list .navigation .wp-paginate .current {
    color: #464646;
    background: transparent;
    border: none;
    font-size: 1.7em;
    font-weight: 600; }
  .media-index-list .navigation .wp-paginate a {
    color: #23185d;
    background: transparent;
    border: none;
    font-size: 1.7em; }
    .media-index-list .navigation .wp-paginate a:hover {
      text-decoration: underline !important; }

.media-nav {
  padding-top: 25px;
  padding-bottom: 25px; }

.back-link {
  color: #e6173e;
  display: block;
  font-size: 2em;
  margin-top: 25px; }

.media-post .inner {
  padding: 15px; }
  .media-post .inner:after {
    content: "";
    display: table;
    clear: both; }

.media-post .meta {
  margin-bottom: 25px; }

.media-post .post-title {
  color: #464646;
  font-size: 3.1em;
  line-height: 37px;
  margin-bottom: 10px; }

.media-post .post-date {
  color: #464646;
  font-size: 1.7em;
  font-style: italic; }

.media-post .attachment-post-thumbnail {
  border: 1px solid #e9e9e9;
  margin-bottom: 25px;
  max-width: 100%;
  height: auto; }

.media-post form {
  background: #e9e9e9;
  border-radius: 5px;
  margin-top: 30px;
  padding: 15px; }
  .media-post form:after {
    content: "";
    display: table;
    clear: both; }
  .media-post form > p {
    color: #898989;
    font-size: 1.7em;
    line-height: 24px;
    margin: 0 0 20px 0; }
  .media-post form .submit_wrapper input {
    border: none;
    font-weight: 600;
    width: 100%; }

.media-post .success {
  background: #e9e9e9;
  border-radius: 5px;
  margin-top: 30px;
  padding: 15px; }
  .media-post .success:after {
    content: "";
    display: table;
    clear: both; }

.archive-search-link {
  color: #e6173e;
  display: block;
  font-size: 1.7em;
  text-align: center;
  vertical-align: middle; }
  .archive-search-link i {
    display: inline-block;
    font-size: 1.7em;
    margin: -5px 5px 0 0;
    vertical-align: middle; }
  .archive-search-link:hover {
    text-decoration: none; }
    .archive-search-link:hover .text {
      text-decoration: underline; }

.events-index > .inner {
  padding: 15px; }

.events-index .event {
  margin-bottom: 30px; }
  .events-index .event .attachment-post-thumbnail {
    border: 1px solid #e9e9e9;
    margin-bottom: 10px;
    max-width: 100%;
    height: auto; }
  .events-index .event .event-title {
    font-size: 2.2em;
    margin-bottom: 10px; }
    .events-index .event .event-title a {
      color: #23185d; }
  .events-index .event .event-description {
    color: #464646;
    font-size: 1.8em;
    line-height: 24px;
    margin-bottom: 5px; }
    .events-index .event .event-description span {
      display: block;
      font-weight: bold; }

.random-fact, .twitter-feed {
  margin: 0 15px 15px 15px; }
  .random-fact > .inner, .twitter-feed > .inner {
    background: #c0c1da;
    text-align: center;
    padding: 25px 15px; }
  .random-fact h2, .twitter-feed h2 {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAACCAIAAAAfCIEKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowNDcxOTM3NC0wMmJmLTRjMmYtOTQwMy05OTkyMGQzZjRmZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTgxRTQwNUE2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTgxRTQwNTk2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxODE3NzBkZC1jZTExLTRiOTktOTYwZS1kNTE0ZTdhMmIwMWIiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1NWI5NTU5NS05OWE2LTExNzctOGZjYy1hZjkzYTQ1ZDQ3NDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5jBSweAAAAF0lEQVR42mI8f+EOAxJgYWJiROYDBBgASyQChkOKeO4AAAAASUVORK5CYII=) repeat-x 0 50%;
    background-size: auto 1px;
    color: #23185d;
    font-size: 2.2em;
    font-weight: 600;
    margin: 0 auto 20px auto; }
    .random-fact h2 > .inner, .twitter-feed h2 > .inner {
      background: #bfc1d8 url("../../img/question-icon.png") no-repeat 10px 50%;
      background-size: 21px 21px;
      -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
      display: inline-block;
      margin: 0 auto 0 auto;
      padding: 0 10px 0 40px; }
  .random-fact ul, .twitter-feed ul {
    list-style-type: none; }
  .random-fact p, .random-fact li, .twitter-feed p, .twitter-feed li {
    color: #23185d;
    font-size: 1.7em;
    font-style: italic;
    line-height: 24px;
    padding: 0 20px; }

.random-testimonial {
  background: #bfc1d8; }
  .random-testimonial > .inner {
    text-align: center;
    padding: 25px 15px; }
  .random-testimonial p {
    color: #FFFFFF;
    font-size: 1.7em;
    font-style: italic;
    line-height: 24px;
    padding: 0 20px; }
  .random-testimonial cite {
    color: #464646;
    display: block;
    font-size: 1.7em;
    font-style: italic;
    text-align: right;
    line-height: 24px;
    padding: 0 20px;
    width: 100%; }

.page-template-search-php .page-large-section h1 {
  margin: 0; }

.page-template-search-php .search-filter-form {
  background: #ebebeb; }
  .page-template-search-php .search-filter-form .inner {
    padding: 15px; }
  .page-template-search-php .search-filter-form fieldset {
    border: none;
    margin: 0 0 15px 0; }
    .page-template-search-php .search-filter-form fieldset legend {
      color: #464646;
      font-size: 1.6em;
      font-weight: 600;
      margin: 0 0 10px 0; }
    .page-template-search-php .search-filter-form fieldset#filter-wrapper label {
      color: #464646;
      display: block;
      font-size: 1.5em;
      line-height: 18px;
      padding: 5px 0;
      vertical-align: middle; }
      .page-template-search-php .search-filter-form fieldset#filter-wrapper label input {
        margin: -3px 5px 0 0;
        vertical-align: middle; }
    .page-template-search-php .search-filter-form fieldset#keywords-wrapper label {
      color: #464646;
      display: block;
      font-size: 1.6em;
      font-weight: 600;
      margin: 0 0 10px 0; }
    .page-template-search-php .search-filter-form fieldset#keywords-wrapper input {
      background: #FFFFFF;
      border: 1px solid #a3a3a3;
      border-radius: 5px;
      color: #464646;
      font-family: 'Lato', 'Arial', sans-serif;
      font-size: 1.5em;
      padding: 5px;
      width: 100%; }
      .page-template-search-php .search-filter-form fieldset#keywords-wrapper input:hover, .page-template-search-php .search-filter-form fieldset#keywords-wrapper input:focus, .page-template-search-php .search-filter-form fieldset#keywords-wrapper input:active {
        border-color: #0a0a0a;
        outline: none; }
    .page-template-search-php .search-filter-form fieldset#date-wrapper label {
      display: none; }
    .page-template-search-php .search-filter-form fieldset#date-wrapper select {
      background: #FFFFFF;
      border: 1px solid #a3a3a3;
      border-radius: 5px;
      color: #464646;
      font-family: 'Lato', 'Arial', sans-serif;
      font-size: 1.5em;
      float: left;
      padding: 5px;
      margin-right: 5px;
      width: 48%; }
      .page-template-search-php .search-filter-form fieldset#date-wrapper select:hover, .page-template-search-php .search-filter-form fieldset#date-wrapper select:focus, .page-template-search-php .search-filter-form fieldset#date-wrapper select:active {
        border-color: #0a0a0a;
        outline: none; }
    .page-template-search-php .search-filter-form fieldset#date-wrapper #search-year {
      float: right;
      margin-right: 0; }

.page-template-search-php .search-results .inner {
  padding: 15px; }

.page-template-search-php .search-results .noresults {
  color: #464646;
  font-size: 1.6em;
  font-weight: 600;
  text-align: center;
  padding: 20px 0; }

.page-template-search-php .search-results .search-result {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding-bottom: 15px; }
  .page-template-search-php .search-results .search-result .attachment-post-thumbnail {
    border: 1px solid #e9e9e9;
    margin: 0 0 15px 0;
    max-width: 100%;
    height: auto; }
  .page-template-search-php .search-results .search-result h2 {
    font-size: 1.6em;
    font-weight: 600;
    margin: 0 0 10px 0; }
    .page-template-search-php .search-results .search-result h2 a {
      color: #d12e3d; }
  .page-template-search-php .search-results .search-result .post-date {
    color: #464646;
    display: block;
    font-size: 1.5em;
    font-style: italic;
    margin-bottom: 10px; }
  .page-template-search-php .search-results .search-result .result-excerpt p {
    color: #898989;
    font-size: 1.5em;
    line-height: 24px; }

.page-template-search-php .pagination a {
  color: #d12e3d;
  display: block;
  font-size: 1.6em;
  text-align: center;
  padding: 10px 0; }

#contact-form .inner {
  background: #ebebeb;
  margin: 0 15px 15px 15px;
  padding: 15px; }

#contact-form .tabs {
  border-bottom-color: #898989;
  margin-top: 0; }

#contact-form .info {
  display: none;
  margin: 25px 0; }
  #contact-form .info.active {
    display: block; }
  #contact-form .info address, #contact-form .info p {
    color: #464646;
    font-size: 1.7em;
    line-height: 24px;
    margin-bottom: 15px; }
    #contact-form .info address .emph, #contact-form .info p .emph {
      color: #464646; }
    #contact-form .info address a, #contact-form .info p a {
      color: #e6173e; }

#contact-form .form-wrapper h2 {
  color: #23185d;
  font-size: 2.2em;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 15px 0; }

#contact-form .form-wrapper #txt_security_wrapper:after {
  content: "";
  display: table;
  clear: both; }

#contact-form .form-wrapper #txt_security_wrapper span {
  color: #464646;
  display: inline-block;
  font-size: 1.7em;
  float: left;
  padding-top: 10px; }
  #contact-form .form-wrapper #txt_security_wrapper span > span {
    color: #d12f3e;
    font-size: 130%;
    float: none;
    padding: 0 5px; }

#contact-form .form-wrapper #txt_security_wrapper input {
  float: left;
  margin-left: 15px;
  width: 80px; }

#contact-form .form-wrapper #txt_security_wrapper p.error {
  clear: both;
  line-height: 22px;
  margin: 0;
  padding-top: 10px; }

.twitter-feed h2 {
  padding: 0 10px; }
  .twitter-feed h2 > .inner {
    background-image: url("../../img/twitter-icon.png");
    background-size: 29px 24px;
    -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc");
    display: block;
    text-indent: -9999px;
    width: 29px; }

.twitter-feed .tweet_time {
  clear: left;
  color: #dddeec;
  display: block;
  font-weight: 300;
  margin-top: 10px; }

#main-footer {
  background: #23185d; }
  #main-footer .inner {
    padding: 15px; }
    #main-footer .inner p {
      color: #FFFFFF;
      font-size: 1.6em;
      font-weight: 300;
      line-height: 22px;
      margin-bottom: 10px; }
      #main-footer .inner p:last-child {
        margin-bottom: 0; }
      #main-footer .inner p.copyright {
        font-size: 1.4em; }
  #main-footer a {
    color: #FFFFFF; }
  #main-footer .contact-info {
    margin-bottom: 15px; }
  #main-footer .social-icons {
    list-style-type: none; }
    #main-footer .social-icons:after {
      content: "";
      display: table;
      clear: both; }
    #main-footer .social-icons li {
      float: left;
      margin-right: 10px; }
      #main-footer .social-icons li.email a {
        display: inline-block;
        font-size: 12px;
        padding: 6px 0; }
      #main-footer .social-icons li img {
        width: 25px;
        height: 25px; }
  #main-footer p.misc {
    font-size: 14px;
    margin-top: 15px; }

.page-template-about-us-php .page-section.section-0 .inner {
  background: transparent; }

.page-template-about-us-php .page-section.section-2 {
  background-position: 100% 50px; }
  .page-template-about-us-php .page-section.section-2 h2 {
    height: 200px; }

.page-template-about-us-php .page-section.section-2 {
  background-position: 50% 50px; }

.page-template-sectors-php .page-section.section-1 {
  -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc"); }
  .page-template-sectors-php .page-section.section-1 h2 {
    height: 150px; }

.page-template-sectors-php .page-section.section-2 {
  -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc"); }
  .page-template-sectors-php .page-section.section-2 h2 {
    height: 180px; }

.page-template-sectors-php .page-section.section-3 {
  -ms-behavior: url("/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc"); }
  .page-template-sectors-php .page-section.section-3 h2 {
    height: 220px; }

.lt-ie9 .page-template-about-us-php .section-1 .section-feature-image {
  height: 307px; }

.lt-ie9 .page-template-about-us-php #find-out-more aside.last {
  margin-right: 0; }

.lt-ie9 .page-template-what-we-do-php #working-with-tiles .tile-2, .lt-ie9 .page-template-what-we-do-php #working-with-tiles .tile-5 {
  margin-right: 0; }

.lt-ie9 .page-template-sectors-php .page-section.section-2 .section-feature-image {
  height: 438px; }

.lt-ie9 .page-template-sectors-php .page-section.section-3 .section-feature-image {
  height: 421px; }

.lt-ie9 .events-index .post-3, .lt-ie9 .events-index .post-7 {
  margin-right: 0; }

.lt-ie9 .single-job #apply-form #txt_position_wrapper, .lt-ie9 .single-job #apply-form #txt_phone_wrapper {
  float: right;
  margin-right: 0; }

.lt-ie9 #contact-form #txt_last_name_wrapper, .lt-ie9 #contact-form #txt_position_wrapper, .lt-ie9 #contact-form #txt_phone_wrapper {
  float: right;
  margin-right: 0; }

.lt-ie9 .page-template-leadership-team-php #leadership-people aside.panel-3 {
  margin-right: 0; }

.lt-ie9 .page-template-leadership-team-php .page-section .section-feature-image {
  height: 207px; }

.lt-ie9 .page-template-what-we-do-php #areas-figure img {
  width: 32%; }

.lt-ie9 .page-template-what-we-do-php #areas-figure img.last {
  margin-right: 0; }

.lt-ie9 .page-section .section-feature-image {
  width: auto; }

.overthrow-enabled .thumbnail-strip {
  position: relative;
  overflow: hidden;
  height: 85px; }
  .overthrow-enabled .thumbnail-strip .inner {
    position: relative;
    overflow: auto !important;
    height: 85px; }
  .overthrow-enabled .thumbnail-strip ul {
    position: absolute;
    height: 85px; }
    .overthrow-enabled .thumbnail-strip ul li {
      margin-bottom: 0; }

.news-updates-subscribe {
  margin: 0 15px 15px 15px; }
  .news-updates-subscribe > .inner {
    background: #c0c1da;
    text-align: center;
    padding: 25px 20px;
    margin-bottom: 60px; }
  .news-updates-subscribe h2 {
    padding: 0 0 25px;
    background-size: auto 1px;
    color: #23185d;
    font-size: 2.2em;
    font-weight: 600;
    margin: 0; }
  .news-updates-subscribe p {
    color: #23185d;
    font-size: 1.7em;
    font-style: italic;
    line-height: 24px;
    padding: 0; }
  .news-updates-subscribe .widget_wysija_cont.php_wysija {
    margin: 12px 0;
    padding: 0;
    font-family: 'Lato', 'Arial', sans-serif;
    font-size: 16px; }
    .news-updates-subscribe .widget_wysija_cont.php_wysija form {
      padding: 0;
      margin: 0; }
    .news-updates-subscribe .widget_wysija_cont.php_wysija .allmsgs li {
      font-family: 'Lato', 'Arial', sans-serif; }
  .news-updates-subscribe .wysija-paragraph {
    display: inline-block;
    position: relative;
    padding: 0;
    /* Override default styles for MailPoet validation */ }
    @media (max-width: 939px) {
      .news-updates-subscribe .wysija-paragraph {
        display: block; } }
    .news-updates-subscribe .wysija-paragraph label {
      display: none; }
    .news-updates-subscribe .wysija-paragraph input[type="text"] {
      background-color: #e4e4e4;
      padding: 0 0 0 20px;
      border: 0 none;
      height: 32px;
      max-width: 90%;
      width: 280px;
      margin: 0;
      font-family: 'Lato', 'Arial', sans-serif;
      font-size: 16px; }
      .news-updates-subscribe .wysija-paragraph input[type="text"]:focus {
        outline: none; }
      @media (max-width: 1179px) {
        .news-updates-subscribe .wysija-paragraph input[type="text"] {
          width: 200px; } }
      @media (max-width: 939px) {
        .news-updates-subscribe .wysija-paragraph input[type="text"] {
          width: 280px;
          display: block;
          margin: 0 auto 20px; } }
    .news-updates-subscribe .wysija-paragraph .formError {
      top: 135% !important;
      left: 0% !important; }
      .news-updates-subscribe .wysija-paragraph .formError .formErrorContent {
        width: 100%;
        padding: 10px !important; }
  .news-updates-subscribe .widget_wysija_cont .allmsgs .updated {
    font-family: 'Lato', 'Arial', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    display: inline-block !important; }
  .news-updates-subscribe .widget_wysija_cont .wysija-submit-field {
    font-family: 'Lato', 'Arial', sans-serif;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block !important;
    background-color: #d12e3d;
    padding: 10px 25px;
    border: 0;
    border-radius: 5px;
    vertical-align: middle;
    margin: 0 10px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .news-updates-subscribe .widget_wysija_cont .wysija-submit-field {
        width: 100%;
        padding: 20px;
        margin: 0; } }

#subscribe-popup {
  position: fixed;
  right: 2%;
  top: 200px;
  z-index: 1001; }
  #subscribe-popup #subscribe-popup-close {
    position: absolute;
    top: 0;
    right: 0; }

@media only screen and (min-width: 600px) {
  .events-index .event:after {
    content: "";
    display: table;
    clear: both; }
  .events-index .event .event-logo {
    float: left;
    margin-right: 15px;
    width: 25%; }
    .events-index .event .event-logo .attachment-post-thumbnail {
      width: 100%; }
  .events-index .event .event-details {
    float: left;
    width: 70%; }
  .events-index .event .event-description span {
    display: inline; } }

/*
Large tablets
*/
@media only screen and (min-width: 768px) {
  .button {
    display: inline-block;
    margin-top: 0;
    padding: 10px 25px; }
  .home #featured-images {
    display: table;
    border-spacing: 3px;
    width: 100%;
    max-width: 100%;
    height: auto; }
    .home #featured-images .inner {
      display: table-row; }
    .home #featured-images .col1 {
      display: table-cell;
      font-size: 0;
      line-height: 0;
      vertical-align: top;
      width: 25%; }
      .home #featured-images .col1 img {
        width: 100%;
        height: auto; }
    .home #featured-images .main-col {
      position: relative;
      width: 50%; }
      .home #featured-images .main-col img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .home #featured-images .col2 {
      display: table-cell;
      font-size: 0;
      line-height: 0;
      vertical-align: top;
      width: 25%; }
      .home #featured-images .col2 img {
        width: 100%;
        height: auto; }
    .home #featured-images .wrapper {
      position: relative;
      margin-bottom: 3px;
      height: 33%; }
      .home #featured-images .wrapper:last-child {
        margin-bottom: 0; }
      .home #featured-images .wrapper img {
        z-index: 100; }
      .home #featured-images .wrapper .overlay {
        background: url("../../img/featured-hover-bg.png");
        background: rgba(97, 98, 129, 0.8);
        position: absolute;
        overflow: hidden;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 0;
        z-index: 200;
        transition: max-height 0.25s ease-out; }
        .home #featured-images .wrapper .overlay a {
          color: #FFFFFF;
          display: block;
          font-size: 22px;
          line-height: 28px;
          font-weight: 300;
          position: absolute;
          top: 0;
          left: 0;
          padding: 20px;
          width: 100%;
          height: 100%;
          z-index: 250; }
          .home #featured-images .wrapper .overlay a:hover {
            text-decoration: none; }
      .home #featured-images .wrapper:hover .overlay {
        max-height: 200px;
        transition: max-height 0.3s ease-in-out; }
  .home .page-content {
    float: left;
    padding-right: 25px;
    width: 50%; }
  .home #consumer-video-wrapper {
    float: right;
    margin-top: 0;
    padding-left: 25px;
    padding-bottom: 28.2%;
    width: 50%; }
  .page-template-about-us-php #find-out-more:after {
    content: "";
    display: table;
    clear: both; }
  .page-template-about-us-php #find-out-more aside {
    margin-right: 15px;
    float: left;
    width: 48.5%; }
    .page-template-about-us-php #find-out-more aside:nth-child(even) {
      float: right;
      margin-right: 0; }
    .page-template-about-us-php #find-out-more aside .button {
      width: 100%; }
  .page-template-what-we-do-php #areas-figure:after {
    content: "";
    display: table;
    clear: both; }
  .page-template-what-we-do-php #areas-figure img {
    float: left;
    padding: 0 2px;
    max-width: 33%; }
  .page-template-what-we-do-php #working-with-tiles:after {
    content: "";
    display: table;
    clear: both; }
  .page-template-what-we-do-php #working-with-tiles .tile {
    float: left;
    padding-right: 15px;
    width: 50%; }
    .page-template-what-we-do-php #working-with-tiles .tile:nth-child(even) {
      padding-right: 0; }
  .page-template-careers-php #gallery-content {
    float: left;
    width: 50%;
    max-width: 620px; }
    .page-template-careers-php #gallery-content #viewer {
      text-align: center;
      margin-bottom: 15px;
      padding: 0 15px; }
      .page-template-careers-php #gallery-content #viewer img {
        max-width: 880px;
        width: 100%; }
      .page-template-careers-php #gallery-content #viewer iframe {
        max-width: 880px; }
    .page-template-careers-php #gallery-content .heading-wrapper {
      margin: 0 auto;
      padding: 0 30px;
      max-width: 1050px; }
    .page-template-careers-php #gallery-content h2 {
      font-size: 1.8em;
      margin: 0 auto 15px auto;
      padding: 0;
      width: 83.8%; }
  .page-template-careers-php .thumbnail-strip {
    margin: 0 auto 25px auto;
    padding: 0 15px;
    max-width: 1050px;
    height: 81px; }
    .page-template-careers-php .thumbnail-strip .fade-left, .page-template-careers-php .thumbnail-strip .fade-right {
      display: none; }
    .page-template-careers-php .thumbnail-strip .scroll-left, .page-template-careers-php .thumbnail-strip .scroll-right {
      background-color: #e6173e;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 9px 13px;
      display: block;
      position: absolute;
      top: 0;
      width: 5%;
      height: 100%;
      transition: background-color 0.2s linear; }
      .page-template-careers-php .thumbnail-strip .scroll-left:hover, .page-template-careers-php .thumbnail-strip .scroll-right:hover {
        background-color: #b81232;
        transition: background-color 0.2s linear; }
    .page-template-careers-php .thumbnail-strip .scroll-left {
      background-image: url("../../img/scroll-left.png");
      left: 15px; }
    .page-template-careers-php .thumbnail-strip .scroll-right {
      background-image: url("../../img/scroll-right.png");
      right: 15px; }
    .page-template-careers-php .thumbnail-strip .inner {
      overflow: hidden !important;
      margin-left: auto;
      margin-right: auto;
      width: 85%; }
    .page-template-careers-php .thumbnail-strip ul {
      left: 0;
      padding: 0; }
  .page-template-careers-php .featured-testimonial {
    float: right;
    width: 50%; }
    .page-template-careers-php .featured-testimonial .testimonial-picture {
      margin-top: 0; }
    .page-template-careers-php .featured-testimonial .testimonial {
      margin-top: -3px; }
  .page-template-careers-php #careers-testimonials .testimonial {
    float: left;
    padding-right: 30px;
    width: 50%; }
  .page-template-careers-php #careers-testimonials .testimonial-picture {
    float: left;
    margin: 0 15px 15px 0;
    max-width: 30%; }
  .page-template-careers-php #careers-testimonials .testimonial-detail {
    font-size: 1.8em;
    line-height: 25px;
    float: right;
    max-width: 64%; }
  .in-the-media-intro {
    display: block;
    margin-bottom: 20px; }
    .in-the-media-intro .inner {
      background: #ebebeb;
      padding: 60px 0; }
      .in-the-media-intro .inner img {
        max-width: 100%;
        height: auto; }
  .tabs li {
    margin-right: 20px; }
    .tabs li a {
      font-size: 1.8em; }
  .media-index-list .media-post .inner:after {
    content: "";
    display: table;
    clear: both; }
  .media-index-list .media-post .attachment-post-thumbnail, .media-index-list .media-post .attachment-large {
    float: left;
    margin: 0 25px 0 0;
    max-width: 36.5%; }
  .media-index-list .media-post .post-title, .media-index-list .media-post .post-date, .media-index-list .media-post .post-excerpt {
    clear: right;
    float: right;
    width: 60%; }
  .media-index-list .media-post form {
    clear: right;
    float: right;
    position: relative;
    margin-top: 20px;
    padding-bottom: 0;
    width: 60%; }
    .media-index-list .media-post form .field_wrapper {
      float: left;
      width: 48%; }
      .media-index-list .media-post form .field_wrapper:nth-child(even) {
        float: right; }
    .media-index-list .media-post form .submit_wrapper {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 35%; }
  .media-index-list .media-post .success {
    clear: right;
    float: right;
    position: relative;
    margin-top: 20px;
    width: 60%; }
  .media-index-list.jobs-list .inner {
    max-width: 1280px; }
  .media-index-list.jobs-list .media-post .post-title, .media-index-list.jobs-list .media-post .post-date, .media-index-list.jobs-list .media-post .post-excerpt {
    float: none;
    width: 100%; }
  .post-type-archive-press-release .media-index-list .media-post .post-title, .post-type-archive-press-release .media-index-list .media-post .post-date, .post-type-archive-press-release .media-index-list .media-post .post-excerpt {
    float: none;
    width: 100%; }
  .events-index .event .attachment-post-thumbnail, .events-index .event .attachment-large {
    margin-right: 30px; }
  .media-nav {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1280px; }
    .media-nav:after {
      content: "";
      display: table;
      clear: both; }
  .navigation {
    float: left;
    padding-top: 26px;
    width: 100%; }
  .archive-search-link {
    float: right;
    margin: 25px 0; }
  .media-post .meta {
    clear: both; }
  .media-post .attachment-post-thumbnail, .media-post .attachment-large {
    float: left;
    max-width: 45%; }
  .media-post .content-wrapper {
    float: right;
    width: 50%; }
  .media-post.job-post .content-wrapper {
    float: none;
    width: 100%; }
  .single-press-release .media-post .content-wrapper {
    float: none;
    width: 100%; } }

@media only screen and (min-width: 940px) {
  #main-header .logo {
    width: 245px; }
  .home #content.has-padding {
    padding: 0; }
    .home #content.has-padding .inner {
      padding: 30px; }
  #mobile-menu, .subpage-menu-link, .subpage-menu {
    display: none; }
  .menu-social {
    bottom: 5px; }
    .menu-social li {
      width: auto; }
      .menu-social li a {
        background: url("../../img/search-icon.png") no-repeat 100% 50%;
        padding-right: 30px;
        padding-top: 3px;
        width: auto;
        height: 20px; }
        .menu-social li a:hover {
          text-decoration: underline; }
  .subpage-menu-container-desktop {
    background: #c0c1da;
    display: block; }
    .subpage-menu-container-desktop:after {
      content: "";
      display: table;
      clear: both; }
    .subpage-menu-container-desktop ul {
      background: url("../../img/subpage-menu-icon.png") no-repeat 30px 50%;
      margin: 0 auto;
      padding: 0 30px 0 70px;
      max-width: 1280px; }
      .subpage-menu-container-desktop ul:after {
        content: "";
        display: table;
        clear: both; }
      .subpage-menu-container-desktop ul li {
        display: block;
        float: left; }
        .subpage-menu-container-desktop ul li a {
          color: #FFFFFF;
          display: block;
          font-size: 1.6em;
          font-weight: 300;
          padding: 20px; }
        .subpage-menu-container-desktop ul li.current-menu-item a {
          color: #0a0a2c;
          font-weight: 600; }
  #main-menu-container {
    display: block; }
    #main-menu-container .menu {
      list-style-type: none;
      position: absolute;
      left: 280px;
      bottom: -30px; }
      #main-menu-container .menu li {
        float: left;
        position: relative; }
        #main-menu-container .menu li a {
          color: #313131;
          display: block;
          font-size: 1.4em;
          padding: 0 6px 35px 6px; }
        #main-menu-container .menu li.current-menu-item > a, #main-menu-container .menu li.current-page-ancestor > a {
          color: #d61226;
          font-weight: 600; }
        #main-menu-container .menu li.menu-item-has-children {
          background: url("../../img/dropdown-arrow.png") no-repeat 50% 25px; }
        #main-menu-container .menu li .sub-menu {
          background: #FFFFFF;
          border: 1px solid #d9d9d9;
          border-bottom: none;
          display: none;
          position: absolute;
          width: 250px;
          z-index: 90000; }
          #main-menu-container .menu li .sub-menu li {
            border-bottom: 1px solid #d9d9d9;
            display: block;
            width: 100%; }
            #main-menu-container .menu li .sub-menu li a {
              background: url("../../img/submenu-item.png") no-repeat 20px 50%;
              display: block;
              padding: 20px 20px 20px 45px; }
        #main-menu-container .menu li:hover .sub-menu {
          display: block; }
  .single-post #main-menu-container .menu li.menu-item-36 > a,
  .post-type-archive-press-release #main-menu-container .menu li.menu-item-36 > a,
  .single-press-release #main-menu-container .menu li.menu-item-36 > a,
  .post-type-archive-in-media #main-menu-container .menu li.menu-item-36 > a,
  .single-in-media #main-menu-container .menu li.menu-item-36 > a,
  .post-type-archive-event #main-menu-container .menu li.menu-item-36 > a,
  .single-event #main-menu-container .menu li.menu-item-36 > a,
  .post-type-archive-collateral #main-menu-container .menu li.menu-item-36 > a,
  .single-collateral #main-menu-container .menu li.menu-item-36 > a,
  .post-type-archive-job #main-menu-container .menu li.menu-item-32 > a,
  .single-job #main-menu-container .menu li.menu-item-32 > a {
    color: #d61226;
    font-weight: 600; }
  .single-press-release .subpage-menu-container-desktop ul li.menu-item-147 a,
  .single-post .subpage-menu-container-desktop ul li.menu-item-154 a,
  .post-type-archive-collateral .subpage-menu-container-desktop ul li.menu-item-164 a,
  .post-type-archive-job .subpage-menu-container-desktop ul li.menu-item-217 a,
  .single-job .subpage-menu-container-desktop ul li.menu-item-217 a {
    color: #d61226;
    font-weight: 600; }
  .menu-item-364 a {
    color: #313131 !important; }
  .page-template-about-us-php .menu-item-364 a {
    color: #d61226 !important; }
  .blog .menu-item-369 a {
    color: #313131 !important; }
  #main-header .inner {
    margin: 0 auto;
    padding: 30px; }
  #main-header #mobile-menu-button {
    display: none; }
  .page-section:after {
    content: "";
    display: table;
    clear: both; }
  .page-section .inner {
    background-size: 50% auto;
    background-position: 0 100%;
    padding: 30px; }
    .page-section .inner:after {
      content: "";
      display: table;
      clear: both; }
  .page-section.alt .inner {
    background-position: 100% 100%; }
  .page-section .section-content {
    clear: right;
    float: right;
    width: 48%; }
  .page-section.alt .section-content {
    float: left;
    clear: left; }
  .page-section.inverse .section-content {
    float: left;
    clear: left; }
  .page-section.inverse.alt .section-content {
    float: right;
    clear: right; }
  .page-section h1, .page-section h2 {
    clear: right;
    float: right;
    margin-bottom: 20px;
    height: auto;
    width: 48%; }
  .page-section.alt h1, .page-section.alt h2 {
    float: left; }
  .page-section.inverse h1, .page-section.inverse h2 {
    float: left; }
  .page-section.inverse.alt h1, .page-section.inverse.alt h2 {
    float: right; }
  .page-section p, .page-section ul, .page-section ol {
    font-size: 1.8em;
    line-height: 25px; }
  .page-section .section-feature-image {
    clear: left;
    float: left;
    margin: 0;
    max-width: 46%; }
  .page-section.alt .section-feature-image {
    float: right; }
  .page-section.inverse .section-feature-image {
    float: right; }
  .page-section.inverse.alt .section-feature-image {
    float: left; }
  .page-large-section .inner {
    padding: 30px; }
  #content {
    padding: 0; }
    #content.page-not-found .inner h1.main-title {
      font-size: 5em; }
  .page-template-about-us-php .section-2 .section-feature-image {
    margin-bottom: -60px; }
  .page-template-about-us-php #find-out-more .inner {
    padding: 40px 30px 15px 30px; }
    .page-template-about-us-php #find-out-more .inner:after {
      content: "";
      display: table;
      clear: both; }
  .page-template-about-us-php #find-out-more h2 {
    font-size: 4.7em; }
  .page-template-about-us-php #find-out-more aside {
    margin-right: 30px;
    width: 22.4%; }
    .page-template-about-us-php #find-out-more aside:nth-child(even) {
      float: left;
      margin-right: 30px; }
    .page-template-about-us-php #find-out-more aside:last-child {
      float: left;
      margin-right: 0; }
  .page-template-leadership-team-php #leadership-intro h1 {
    margin-bottom: 35px; }
  .page-template-leadership-team-php #leadership-intro .attachment-post-thumbnail, .page-template-leadership-team-php #leadership-intro .attachment-large {
    float: right;
    margin-top: 0;
    max-width: 48%; }
  .page-template-leadership-team-php #leadership-intro .content-wrapper {
    float: left;
    width: 48%; }
  .page-template-leadership-team-php #leadership-people {
    margin: 0 0 40px 0; }
    .page-template-leadership-team-php #leadership-people:after {
      content: "";
      display: table;
      clear: both; }
    .page-template-leadership-team-php #leadership-people aside {
      margin-bottom: 0;
      margin-right: 30px;
      float: left;
      width: 22%; }
      .page-template-leadership-team-php #leadership-people aside:last-child {
        margin-right: 0; }
  .page-template-leadership-team-php .page-section h2 {
    clear: both;
    display: block;
    width: 100%; }
  .single-press-release .media-post .content-wrapper {
    float: none;
    width: 100%; }
  .page-template-what-we-do-php #working-with-tiles {
    margin-top: 50px;
    /* .tile {
				padding-right: 0;
				margin-right: 70px;
				width: 28%;
				min-height: 260px;

				&:nth-child(even) {
					padding-right: 0;
				}

				&:nth-child(3n+3) {
					margin-right: 0;
				}

				p {
					float: right;
					width: 41%;
				}
			} */ }
  .page-template-sectors-php .sectors-intro {
    float: left;
    width: 48%; }
  .page-template-sectors-php .sectors-chart {
    float: right;
    max-width: 48%; }
  .page-template-careers-php #careers-feature {
    padding: 30px 0; }
  .page-template-careers-php #gallery-content #viewer {
    padding: 0 30px; }
  .page-template-careers-php #gallery-content .thumbnail-strip {
    padding: 0 30px; }
    .page-template-careers-php #gallery-content .thumbnail-strip .scroll-left {
      left: 30px; }
    .page-template-careers-php #gallery-content .thumbnail-strip .scroll-right {
      right: 30px; }
  .page-template-careers-php .featured-testimonial {
    padding: 0 30px; }
  .page-template-careers-php #careers-testimonials .inner {
    padding: 30px; }
  .page-template-careers-php #careers-testimonials .testimonial-detail {
    max-width: 63%; }
  .single-job #apply-form:after {
    content: "";
    display: table;
    clear: both; }
  .single-job #apply-form .inner {
    padding: 30px; }
    .single-job #apply-form .inner:after {
      content: "";
      display: table;
      clear: both; }
    .single-job #apply-form .inner > h2, .single-job #apply-form .inner > p {
      clear: left;
      float: left;
      width: 45%; }
    .single-job #apply-form .inner .success {
      clear: none;
      float: right;
      margin-top: -40px;
      width: 50%; }
  .single-job #apply-form form {
    float: right;
    margin-top: -40px;
    width: 50%; }
    .single-job #apply-form form:after {
      content: "";
      display: table;
      clear: both; }
  .single-job #apply-form h2 {
    font-size: 3.1em;
    margin-bottom: 15px; }
  .single-job #apply-form p {
    font-size: 1.7em;
    line-height: 24px;
    margin: 0 0 25px 0; }
  .single-job #apply-form .field_wrapper {
    float: left;
    margin: 0 0 30px 0;
    width: 47%; }
    .single-job #apply-form .field_wrapper:nth-child(2n+2) {
      float: right; }
    .single-job #apply-form .field_wrapper label {
      font-size: 1.7em;
      margin-bottom: 8px; }
    .single-job #apply-form .field_wrapper#txt_attachment_wrapper {
      margin-bottom: 0; }
  .single-job #apply-form .form_submit {
    float: right;
    padding: 10px 30px;
    width: auto; }
  .page-template-media-index-php .page-section {
    background-position: 0 50%; }
    .page-template-media-index-php .page-section.alt {
      background-position: 100% 50%; }
    .page-template-media-index-php .page-section .section-feature-image {
      width: auto;
      height: auto;
      max-width: 50%;
      max-height: 100%; }
  .in-the-media-intro .logo1 {
    width: 285px; }
  .in-the-media-intro .logo2 {
    width: 160px; }
  .in-the-media-intro .logo3 {
    width: 230px; }
  .in-the-media-intro .logo4 {
    width: 265px; }
  .media-index-list .media-post .inner {
    margin: 0 30px;
    padding: 30px 0; }
  .media-index-list .media-post .attachment-post-thumbnail, .media-index-list .media-post .attachment-large {
    max-width: 33.5%; }
  .media-index-list .media-post .post-title, .media-index-list .media-post .post-date, .media-index-list .media-post .post-excerpt {
    width: 63.5%; }
  .media-index-list .media-post.press-release.first-post .attachment-post-thumbnail, .media-index-list .media-post.press-release.first-post .attachment-large, .media-index-list .media-post.post.first-post .attachment-post-thumbnail, .media-index-list .media-post.post.first-post .attachment-large {
    max-width: 46%; }
  .media-index-list .media-post.press-release.first-post .post-title, .media-index-list .media-post.press-release.first-post .post-date, .media-index-list .media-post.press-release.first-post .post-excerpt, .media-index-list .media-post.post.first-post .post-title, .media-index-list .media-post.post.first-post .post-date, .media-index-list .media-post.post.first-post .post-excerpt {
    width: 50%; }
  .media-index-list .media-post form {
    border-radius: 10px;
    padding: 20px 20px 8px 20px;
    width: 63.5%; }
    .media-index-list .media-post form .submit_wrapper {
      bottom: 22px;
      right: 20px;
      width: 210px; }
  .media-index-list .media-post .success {
    border-radius: 10px;
    padding: 20px;
    width: 63.5%; }
  .media-index-list.jobs-list .inner {
    padding: 0 30px; }
  .post-type-archive-press-release .media-index-list .media-post .post-title, .post-type-archive-press-release .media-index-list .media-post .post-date, .post-type-archive-press-release .media-index-list .media-post .post-excerpt {
    float: none;
    width: 100% !important; }
  .media-index-intro .inner {
    padding: 30px; }
  .media-index-intro .attachment-full {
    margin-top: 30px; }
  .events-index > .inner {
    margin: 0 auto;
    padding: 0 30px;
    max-width: 1280px; }
  .media-post .inner {
    padding: 30px; }
  .media-nav {
    padding: 0 30px; }
  .gf_name_has_2 span {
    display: inline-block;
    width: 48%; }
    .gf_name_has_2 span label {
      display: block;
      margin-top: 7.5px;
      opacity: 0.5; }
    .gf_name_has_2 span:last-of-type {
      margin-left: 2%; }
  #contact-form .inner {
    padding: 30px; }
  #contact-form .tabs {
    margin-bottom: 30px; }
  #contact-form .info {
    float: left;
    margin-top: 0;
    width: 45%; }
  #contact-form .form-wrapper {
    float: right;
    width: 50%; }
  #contact-form .field_wrapper {
    float: left;
    width: 45%; }
    #contact-form .field_wrapper:nth-child(2n+2) {
      float: right; }
  #contact-form #txt_query_wrapper {
    clear: both;
    float: none;
    width: 100%; }
  #contact-form #txt_security_wrapper {
    margin-bottom: 0; }
  #contact-form .form_submit {
    float: right;
    margin-top: -2px;
    width: 150px; }
  .gfield {
    float: left;
    width: 100%; }
    .gfield.left {
      float: left;
      width: 47%; }
    .gfield.right {
      float: right;
      width: 47%; }
  .gform_footer {
    float: left;
    width: 100%; }
  .page-template-search-php .page-large-section h1 {
    margin: 0; }
  .page-template-search-php .search-filter-form .inner {
    padding: 30px; }
  .page-template-search-php .search-filter-form fieldset {
    margin: 0 0 20px 0; }
    .page-template-search-php .search-filter-form fieldset legend {
      font-size: 1.8em;
      float: left;
      margin: 0;
      width: 115px; }
    .page-template-search-php .search-filter-form fieldset#filter-wrapper label {
      display: inline-block;
      margin: 0 30px 0 0;
      padding: 0; }
      .page-template-search-php .search-filter-form fieldset#filter-wrapper label input {
        margin: -3px 5px 0 0;
        vertical-align: middle; }
    .page-template-search-php .search-filter-form fieldset#keywords-wrapper {
      float: left;
      width: 50%; }
      .page-template-search-php .search-filter-form fieldset#keywords-wrapper label {
        float: left;
        font-size: 1.8em;
        font-weight: 600;
        margin: 0;
        padding: 12px 0 0 0;
        width: 115px; }
      .page-template-search-php .search-filter-form fieldset#keywords-wrapper input {
        padding: 10px;
        width: 280px; }
    .page-template-search-php .search-filter-form fieldset#date-wrapper {
      float: left;
      width: 37%; }
      .page-template-search-php .search-filter-form fieldset#date-wrapper legend {
        padding: 12px 0 0 0;
        width: 70px; }
      .page-template-search-php .search-filter-form fieldset#date-wrapper select {
        padding: 10px;
        width: 120px; }
      .page-template-search-php .search-filter-form fieldset#date-wrapper #search-year {
        float: left;
        margin-right: 0; }
    .page-template-search-php .search-filter-form fieldset#keywords-wrapper, .page-template-search-php .search-filter-form fieldset#date-wrapper {
      margin-bottom: 0; }
  .page-template-search-php .search-filter-form .form_submit {
    margin: 2px 0 0 0;
    float: right;
    width: auto; }
  .page-template-search-php .search-results .inner {
    padding: 30px;
    min-height: 300px; }
  .page-template-search-php .search-results .noresults {
    font-size: 2.4em;
    padding: 40px 0; }
  .page-template-search-php .search-results .search-result {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 30px;
    padding-bottom: 30px; }
    .page-template-search-php .search-results .search-result:after {
      content: "";
      display: table;
      clear: both; }
    .page-template-search-php .search-results .search-result .attachment-post-thumbnail, .page-template-search-php .search-results .search-result .attachment-large {
      float: left;
      max-width: 30%; }
    .page-template-search-php .search-results .search-result h2 {
      font-size: 2.7em;
      font-weight: 600;
      float: right;
      margin: 0 0 10px 0;
      width: 68%; }
      .page-template-search-php .search-results .search-result h2 a {
        color: #d12e3d; }
    .page-template-search-php .search-results .search-result .post-date {
      color: #464646;
      display: block;
      font-size: 1.8em;
      font-style: italic;
      margin-bottom: 20px;
      float: right;
      width: 68%; }
    .page-template-search-php .search-results .search-result .result-excerpt {
      float: right;
      width: 68%; }
      .page-template-search-php .search-results .search-result .result-excerpt p {
        color: #898989;
        font-size: 1.8em;
        line-height: 25px; }
  .page-template-search-php .pagination a {
    display: inline-block; }
  .page-template-search-php .pagination .next {
    float: right; }
  .page-template-search-php .pagination .prev {
    float: left; }
  #main-footer .inner {
    padding: 30px; }
    #main-footer .inner:after {
      content: "";
      display: table;
      clear: both; }
  .random-fact > .inner, .twitter-feed > .inner {
    padding: 40px 60px; }
  .random-fact h2 > .inner, .twitter-feed h2 > .inner {
    background-position: 20px 50%;
    padding: 0 20px 0 50px; }
  .twitter-feed h2 > .inner {
    padding: 0 20px;
    width: 59px; }
  .random-testimonial > .inner {
    padding: 40px 60px; }
  .page-template-about-us-php .page-section.section-0 {
    background-position: 100% 100%;
    background-size: 50% auto; }
  .page-template-about-us-php .page-section.section-2 {
    background-position: 100% 100%;
    background-size: 50% auto; }
    .page-template-about-us-php .page-section.section-2 h2 {
      height: auto; }
  .page-template-sectors-php .page-section.section-1 .inner {
    min-height: 380px; }
  .page-template-sectors-php .page-section.section-1 h2 {
    height: auto; }
  .page-template-sectors-php .page-section.section-2 .inner {
    min-height: 380px; }
  .page-template-sectors-php .page-section.section-2 h2 {
    height: auto; }
  .page-template-sectors-php .page-section.section-3 .inner {
    min-height: 455px; }
  .page-template-sectors-php .page-section.section-3 h2 {
    height: auto; }
  .page-template-media-index-php .page-section.section-3 .section-feature-image {
    clear: none; }
  .page-template-media-index-php .page-section.section-4 .section-feature-image {
    clear: none;
    margin-left: 5%; }
  .page-template-media-index-php .page-section.section-5 .section-feature-image {
    clear: none; } }

/*
Desktops
*/
@media only screen and (min-width: 1000px) {
  #main-menu-container .menu li a {
    font-size: 1.5em; }
  .page-template-careers-php #careers-feature .inner {
    padding: 20px; }
  #main-footer .contact-info {
    float: left;
    margin-bottom: 0; }
  #main-footer .social-icons {
    float: right; }
    #main-footer .social-icons li {
      margin-right: 0;
      margin-left: 15px; }
      #main-footer .social-icons li.email {
        padding: 8px 0; }
      #main-footer .social-icons li img {
        width: 35px;
        height: 35px; }
  #main-footer .inner {
    position: relative; }
  #main-footer p.misc {
    border-top: none;
    text-align: right;
    position: absolute;
    right: 30px;
    bottom: 30px;
    margin-top: 0;
    padding-top: 0; }
    #main-footer p.misc .credit {
      margin-top: 0; } }

@media only screen and (min-width: 1140px) {
  #content {
    padding: 30px 0; }
  .inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 1280px; }
  .menu-social li a {
    font-size: 1.6em; }
    .menu-social li a i {
      font-size: 2.2em; }
  #main-menu-container .menu li a {
    font-size: 1.8em;
    padding: 0 15px 35px 15px; }
  .page-content h1, .page-content h2 {
    margin-bottom: 20px; }
  .page-content p {
    font-size: 1.8em;
    line-height: 25px;
    margin-bottom: 30px; }
  .page-content ul, .page-content ol {
    font-size: 18px;
    margin-bottom: 30px; }
    .page-content ul ul, .page-content ul ol, .page-content ol ul, .page-content ol ol {
      margin-top: 10px; }
  .page-large-section .inner {
    padding: 30px; }
  .home #content.has-padding {
    padding: 20px 0; }
  .home .inner {
    padding: 30px; }
  .home #featured-images {
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px; }
  .page-template-what-we-do-php #working-with-tiles .tile {
    width: 28.9%;
    padding-right: 0;
    margin-right: 70px;
    min-height: 150px; }
    .page-template-what-we-do-php #working-with-tiles .tile:nth-child(even) {
      padding-right: 0; }
    .page-template-what-we-do-php #working-with-tiles .tile:nth-child(3n+3) {
      margin-right: 0; }
    .page-template-what-we-do-php #working-with-tiles .tile p {
      float: right;
      width: 53.5%; }
  .page-template-careers-php #gallery-content .heading-wrapper {
    padding: 0; }
  .page-template-careers-php .thumbnail-strip {
    padding: 0; }
    .page-template-careers-php .thumbnail-strip .scroll-left {
      left: 0; }
    .page-template-careers-php .thumbnail-strip .scroll-right {
      right: 0; }
  .media-index-list .media-post .inner {
    margin: 0 30px;
    padding: 30px 0; }
  .media-index-list .media-post .attachment-post-thumbnail, .media-index-list .media-post .attachment-large {
    max-width: 27.5%; }
  .media-index-list .media-post .post-title, .media-index-list .media-post .post-date, .media-index-list .media-post .post-excerpt {
    width: 70%; }
  .media-index-list .media-post form, .media-index-list .media-post .success {
    width: 70%; }
  .page-template-search-php .search-filter-form fieldset#keywords-wrapper, .page-template-search-php .search-filter-form fieldset#date-wrapper {
    margin-bottom: 0; }
  .page-template-search-php .search-filter-form fieldset#date-wrapper {
    width: 31%; }
  .page-template-search-php .search-filter-form .form_submit {
    margin: 2px 0 0 0; }
  .random-fact, .twitter-feed {
    margin-bottom: 60px; }
    .random-fact > .inner, .twitter-feed > .inner {
      padding: 50px 120px; }
  .random-testimonial > .inner {
    padding: 50px 120px; }
  .random-testimonial p, .random-testimonial cite {
    font-size: 2em;
    line-height: 24px; }
  .page-template-about-us-php .page-section.section-0 {
    background-position: 100% 100%;
    background-size: 50% auto; }
  .page-template-about-us-php .page-section.section-2 {
    background-position: 100% 100%;
    background-size: 50% auto; }
    .page-template-about-us-php .page-section.section-2 h2 {
      height: auto; }
  .page-template-sectors-php .page-section.section-1 {
    background-size: 50% auto;
    background-position: 100% 100%; }
    .page-template-sectors-php .page-section.section-1 h2 {
      height: auto; }
  .page-template-sectors-php .page-section.section-2 .section-feature-image {
    margin-bottom: 0; } }

@media only screen and (min-width: 1250px) {
  .page-template-leadership-team-php #leadership-people aside {
    width: 23%; }
  .media-index-list .media-post .inner {
    margin: 0 auto 0 auto;
    padding: 30px 0;
    max-width: 1220px; }
  .page-template-about-us-php #find-out-more aside {
    width: 23.1%; } }

@media only screen and (min-width: 1310px) {
  .page-section .inner {
    margin: 30px auto; }
  .page-template-careers-php #careers-feature .inner {
    margin: 0 auto;
    padding: 30px; }
  #contact-form .inner {
    margin-left: auto;
    margin-right: auto; }
  .random-fact, .twitter-feed {
    margin: 0 auto 60px auto; }
  .in-the-media-intro img {
    width: auto !important;
    height: auto !important; }
  .page-template-media-index-php .page-section.section-3 .section-feature-image {
    clear: none;
    margin-right: 5%; }
  .page-template-media-index-php .page-section.section-4 .section-feature-image {
    clear: none;
    margin-left: 10%; }
  .page-template-media-index-php .page-section.section-5 .section-feature-image {
    clear: none;
    margin-right: 5%; } }

@media only screen and (min-width: 1500px) {
  .page-template-about-us-php .page-section.section-0 {
    background-position: 100% 100%;
    background-size: auto; }
  .page-template-about-us-php .page-section.section-2 {
    background-position: 100% 100%;
    background-size: auto; }
    .page-template-about-us-php .page-section.section-2 h2 {
      height: auto; }
  .page-template-sectors-php .page-section.section-1 {
    background-size: 50% auto;
    background-position: 100% 100%; }
    .page-template-sectors-php .page-section.section-1 h2 {
      height: auto; }
  .page-template-sectors-php .page-section.section-2 .section-feature-image {
    margin-bottom: 0; } }

/*!
 * Bootstrap Grid v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
.landing-page-content-section {
  margin: 15px 0;
  padding-block: 0; }
  @media screen and (min-width: 992px) {
    .landing-page-content-section {
      margin: 60px 0; } }
  @media (min-width: 985px) {
    .landing-page-content-section .inner {
      padding-block: 0; } }
  .landing-page-content-section h1 {
    font-size: 27px;
    color: #d61226;
    margin-bottom: 20px; }
  .landing-page-content-section h2 {
    font-size: 24px;
    color: #23185d; }
  .landing-page-content-section p {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 25px; }
    .landing-page-content-section p:last-of-type {
      margin-bottom: 0; }
  .landing-page-content-section img {
    width: 100%; }
  .landing-page-content-section .wp-video, .landing-page-content-section img {
    margin-block: 30px; }
  @media screen and (min-width: 992px) {
    .landing-page-content-section.media-centred .wp-video, .landing-page-content-section.media-centred img {
      max-width: 50%;
      width: 100%;
      height: auto;
      display: flex; }
      .landing-page-content-section.media-centred .wp-video.aligncenter, .landing-page-content-section.media-centred img.aligncenter {
        margin-inline: auto; }
      .landing-page-content-section.media-centred .wp-video.alignright, .landing-page-content-section.media-centred img.alignright {
        margin-left: auto; }
      .landing-page-content-section.media-centred .wp-video.alignleft, .landing-page-content-section.media-centred img.alignleft {
        margin-right: auto; } }
  .landing-page-content-section.media-full-width .wp-video, .landing-page-content-section.media-full-width img {
    width: 100%;
    height: auto; }

.landing-page-table-image-area-section {
  margin: 15px 0; }
  @media screen and (min-width: 992px) {
    .landing-page-table-image-area-section {
      margin: 60px 0; } }
  .landing-page-table-image-area-section .inner.has-white-background {
    background-color: white; }
  .landing-page-table-image-area-section .inner.has-grey-background {
    background-color: #ebebeb; }
  @media (max-width: 986px) {
    .landing-page-table-image-area-section .inner .row.image-top {
      flex-direction: column-reverse !important; }
    .landing-page-table-image-area-section .inner .row.text-top {
      flex-direction: column !important; }
    .landing-page-table-image-area-section .inner .row > div:first-of-type {
      order: 2; } }
  @media (min-width: 985px) {
    .landing-page-table-image-area-section .inner .row.row-reverse {
      flex-direction: row-reverse !important; } }
  .landing-page-table-image-area-section .inner .row.image-centred {
    display: grid;
    flex-direction: column-reverse !important;
    justify-content: center;
    margin-inline: auto;
    max-width: 655px; }
    .landing-page-table-image-area-section .inner .row.image-centred h2 {
      margin-top: 25px; }
    .landing-page-table-image-area-section .inner .row.image-centred p {
      margin-top: 25px; }
    .landing-page-table-image-area-section .inner .row.image-centred > div {
      padding-inline: 0;
      margin-inline: auto;
      max-width: 100%; }
      @media (min-width: 985px) {
        .landing-page-table-image-area-section .inner .row.image-centred > div {
          padding-inline: 15px; } }
      .landing-page-table-image-area-section .inner .row.image-centred > div:first-of-type {
        order: 2; }
    .landing-page-table-image-area-section .inner .row.image-centred img {
      width: inherit;
      height: auto; }
      @media (min-width: 985px) {
        .landing-page-table-image-area-section .inner .row.image-centred img {
          width: auto; } }
  .landing-page-table-image-area-section h2 {
    font-size: 24px;
    color: #23185d;
    margin-top: 30px; }
    @media (min-width: 985px) {
      .landing-page-table-image-area-section h2 {
        margin-top: 0; } }
  .landing-page-table-image-area-section p {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 25px;
    margin-top: 25px; }
    @media (min-width: 985px) {
      .landing-page-table-image-area-section p {
        margin-top: 0; } }
  .landing-page-table-image-area-section img {
    width: 100%; }

.video-area-section .video-area-video {
  max-width: 100%;
  width: 100%;
  height: auto; }
  .video-area-section .video-area-video:hover {
    cursor: pointer; }

.video-area-section video.video-area-video {
  display: flex; }

.video-area-section .video-wrapper {
  position: relative; }
  .video-area-section .video-wrapper.playing iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    display: block; }
  .video-area-section .video-wrapper iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    display: none; }
  .video-area-section .video-wrapper .iframe-poster {
    display: block;
    width: 100%;
    height: auto; }
    .video-area-section .video-wrapper .iframe-poster:hover {
      cursor: pointer; }

.video-area-section.media-centred .video-wrapper {
  padding-bottom: 56%; }
  @media screen and (min-width: 992px) {
    .video-area-section.media-centred .video-wrapper {
      padding-bottom: 28%; } }

@media screen and (min-width: 992px) {
  .video-area-section.media-centred .video-area-video, .video-area-section.media-centred img {
    max-width: 50%;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-inline: auto; } }

.video-area-section.media-full-width .video-area-video, .video-area-section.media-full-width img {
  width: 100%;
  height: auto; }

.signposts {
  margin: 15px 0; }
  @media screen and (min-width: 992px) {
    .signposts {
      margin: 60px 0; } }
  .signposts .inner {
    background-color: #ebebeb; }
  .signposts h2 {
    color: #d61226;
    text-align: center;
    font-size: 24px; }
    .signposts h2 span {
      background-color: #ebebeb;
      position: relative;
      padding: 0 20px; }
    .signposts h2:before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #FFF;
      left: 0;
      position: relative;
      display: block;
      top: 15px; }
  .signposts h3 {
    font-size: 24px;
    color: #23185d;
    padding-top: 15px; }
  .signposts img {
    max-width: 100%;
    width: 100%;
    transition: all .3s linear; }
    .signposts img:hover {
      filter: blur(2px); }
  .signposts .text {
    margin-top: 5px; }
    .signposts .text p {
      font-size: 17px; }

.image-with-text {
  margin: 15px 0; }
  @media screen and (min-width: 992px) {
    .image-with-text {
      margin: 60px 0; } }
  .image-with-text .inner {
    background-color: #ebebeb; }
    @media (max-width: 986px) {
      .image-with-text .inner .row.image-top {
        flex-direction: column-reverse !important; }
        .image-with-text .inner .row.image-top img {
          margin-bottom: 30px; }
      .image-with-text .inner .row.text-top {
        flex-direction: column !important; }
        .image-with-text .inner .row.text-top img {
          margin-bottom: 30px; } }
    @media (min-width: 985px) {
      .image-with-text .inner .row.row-reverse {
        flex-direction: row-reverse !important; } }
  .image-with-text h2 {
    font-size: 24px;
    color: #23185d; }
  .image-with-text p {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 25px; }
  .image-with-text img {
    width: 100%; }

.banner {
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media (min-width: 985px) {
    .banner {
      min-height: 400px; } }

/**
* .cta--banner
*/
.cta--banner {
  background: #23185d;
  padding: 1rem;
  overflow: hidden;
  width: 100%;
  margin-top: 2rem; }
  .cta--banner p {
    color: white; }
    @media screen and (min-width: 768px) {
      .cta--banner p {
        font-size: 24px; }
        .cta--banner p.cta--banner__text {
          line-height: 60px;
          margin-bottom: 0; } }
  .cta--banner__text, .cta--banner__btn {
    float: left;
    width: 100%;
    padding: 0.25rem; }
    @media screen and (min-width: 768px) {
      .cta--banner__text, .cta--banner__btn {
        max-width: 50%; } }
  .cta--banner__text {
    color: white;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .cta--banner__text {
        text-align: left;
        line-height: 60px; } }
  .cta--banner__btn {
    margin-bottom: 0; }
    .cta--banner__btn a {
      color: white;
      background-color: #d61226;
      display: block;
      width: 100%;
      text-align: center;
      line-height: 35px;
      border: 3px solid transparent; }
      .cta--banner__btn a:hover {
        background-color: white;
        border-color: #d61226;
        text-decoration: none;
        color: #d61226; }
      @media screen and (min-width: 768px) {
        .cta--banner__btn a {
          line-height: 60px; } }

/**
* section
*/
section {
  overflow: hidden;
  width: 100%; }

.form-component h1 {
  font-size: 34px;
  color: #d61226; }

.form-component h2 {
  font-size: 24px;
  color: #23185d; }

.form-component p {
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 25px; }

.form-component .form {
  background-color: #d61226;
  color: white;
  padding: 20px; }
  @media screen and (min-width: 992px) {
    .form-component .form.negative-margin {
      margin-top: -100px; } }
  .form-component .form h2 {
    font-size: 24px;
    color: white; }
  .form-component .form ul {
    list-style: none; }
    .form-component .form ul li {
      margin: 0 0 20px 0; }
  .form-component .form .hidden_label .gfield_label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }
  .form-component .form input {
    border: none;
    border-radius: 0;
    padding: 15px; }
  .form-component .form input[type=text]:focus {
    outline: solid 4px #23185d; }
  .form-component .form select {
    width: 100%;
    height: 38.4px;
    padding: 12px;
    border: none;
    font-family: 'Lato', 'Arial', sans-serif;
    color: #757575;
    font-size: 1.5em; }
  .form-component .form .validation_message, .form-component .form .validation_error {
    color: white;
    font-size: 17px;
    margin-top: 5px; }
  .form-component .form .validation_error {
    margin-bottom: 10px; }
  .form-component .form .gform_confirmation_message, .form-component .form .gform_confirmation_message * {
    color: white; }
  .form-component .form .gform_download {
    list-style: initial;
    color: white;
    font-size: 16px;
    margin-left: 16px; }
    .form-component .form .gform_download li a {
      color: white; }
  .form-component .form input[type=submit] {
    background-color: #23185d;
    border: solid 2px #23185d;
    font-weight: bold;
    font-size: 24px;
    padding: 20px 0;
    width: 100%; }
    .form-component .form input[type=submit]:hover, .form-component .form input[type=submit]:focus {
      background-color: white;
      color: #23185d; }
  .form-component .form .gform_footer {
    float: unset; }

@media screen and (min-width: 640px) {
  .footer-form-section .gf_left_third, .footer-form-section .gf_middle_third {
    padding-right: 10px; } }

.footer-form-section ul {
  list-style: none; }
  .footer-form-section ul li {
    margin: 0 0 20px 0; }

.footer-form-section .hidden_label .gfield_label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.footer-form-section input {
  border: none;
  border-radius: 0;
  padding: 15px;
  width: 100%; }

.footer-form-section input[type=text]:focus {
  outline: solid 4px #23185d; }

.footer-form-section select {
  height: 48.4px;
  padding: 12px;
  border: none;
  font-family: 'Lato', 'Arial', sans-serif;
  color: #757575;
  font-size: 1.5em;
  width: 100%; }

.footer-form-section .validation_message, .footer-form-section .validation_error {
  color: white;
  font-size: 17px;
  margin-top: 5px; }

.footer-form-section .validation_error {
  margin-bottom: 10px;
  color: #d61226; }

.footer-form-section .gform_confirmation_message, .footer-form-section .gform_confirmation_message * {
  color: white; }

.footer-form-section .gform_footer {
  float: unset;
  display: flex;
  justify-content: flex-end; }

.footer-form-section input[type=submit] {
  background-color: #d61226;
  border: solid 2px #d61226;
  font-weight: bold;
  font-size: 22px;
  padding: 10px 20px;
  text-transform: unset;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  @media screen and (min-width: 768px) {
    .footer-form-section input[type=submit] {
      width: 25%; } }
  .footer-form-section input[type=submit]:hover, .footer-form-section input[type=submit]:focus {
    background-color: white;
    color: #d61226; }

.footer-form-section textarea {
  height: 100px;
  border: 0;
  border-radius: 0; }
  .footer-form-section textarea:focus {
    outline: solid 4px #23185d; }

input[type=radio] label, input[type=checkbox] label {
  color: white; }

.ginput_container_checkbox label, .ginput_container_radio label {
  display: inline-block;
  color: white; }

.gfield_checkbox li, .gfield_radio li {
  margin-bottom: -10px !important; }

.gfield_html {
  margin-bottom: 0 !important; }

.content-area-section {
  margin: 15px 0; }
  @media screen and (min-width: 992px) {
    .content-area-section {
      margin: 60px 0; } }
  .content-area-section .inner {
    background-color: #ebebeb; }
  .content-area-section h1 {
    font-size: 34px;
    color: #d61226;
    margin-bottom: 20px; }
  .content-area-section h2 {
    font-size: 24px;
    color: #23185d;
    margin-bottom: 20px; }
  .content-area-section h3 {
    font-size: 20px;
    color: #23185d;
    margin-bottom: 20px; }
  .content-area-section p {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 25px; }

#contact-form .gfield_label {
  font-size: 1.7em;
  margin-bottom: 5px;
  font-weight: 400; }

#contact-form .gfield_required {
  font-size: 18px; }

#contact-form .gform_footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  #contact-form .gform_footer input[type="submit"] {
    width: 100%; }
    @media screen and (min-width: 767px) {
      #contact-form .gform_footer input[type="submit"] {
        width: 150px; } }

.what-we-do {
  display: flex;
  margin-left: -2px;
  margin-right: -2px;
  flex-direction: column; }
  @media screen and (min-width: 992px) {
    .what-we-do {
      flex-direction: row; } }
  @media screen and (max-width: 991px) {
    .what-we-do {
      max-width: 430px;
      margin: auto; } }
  .what-we-do__panel {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    flex: 1;
    margin: 0 2px; }
    @media screen and (max-width: 991px) {
      .what-we-do__panel {
        margin-bottom: 4px; } }
    .what-we-do__panel__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 90px 30px;
      z-index: 2; }
      .what-we-do__panel__inner .what-we-do__panel__icon {
        margin-bottom: 10px; }
      .what-we-do__panel__inner .what-we-do__panel__title {
        color: white;
        font-size: 50px;
        font-weight: 300;
        margin-bottom: 10px; }
      .what-we-do__panel__inner .what-we-do__panel__list {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center; }
        .what-we-do__panel__inner .what-we-do__panel__list li {
          color: white; }
