.image-with-text {
  margin: 15px 0;
  @media screen and (min-width: 992px) {
    margin: 60px 0;
  }

  .inner {
    background-color: $secondary;

    .row {

      @media (max-width: 986px) {
        &.image-top {
          flex-direction: column-reverse!important;

          img {
            margin-bottom: 30px;
          }
        }

        &.text-top {
          flex-direction: column!important;
          img {
            margin-bottom: 30px;
          }
        }
      }


      &.row-reverse {
        @media (min-width: 985px) {
          flex-direction: row-reverse !important;
        }
      }
    }
  }

  h2 {
    font-size: 24px;
    color: $tertiary;
  }
  p {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  img {
    width: 100%;
  }
}
