/**
* .cta--banner
*/

.cta--banner {

	background: $tertiary;
	padding: 1rem;
	overflow: hidden;
	width: 100%;
	margin-top: 2rem;

	p {

		color: white;

		@media screen and (min-width: 768px) {
		  
			font-size: 24px;

			&.cta--banner__text {
				line-height: 60px;
				margin-bottom: 0;
			}

		}

	}

	&__text,
	&__btn {

		float: left;
		width: 100%;
		padding: 0.25rem;

		@media screen and (min-width: 768px) {
		  
			max-width: 50%;

		}

	}

	&__text {

		color: white;
		text-align: center;


		@media screen and (min-width: 768px) {
		  
			text-align: left;
			line-height: 60px;

		}

	}

	&__btn {

		margin-bottom: 0;

		a {

			color: white;
			background-color: $primary;
			display: block;
			width: 100%;
			text-align: center;
			line-height: 35px;
			border: 3px solid transparent;

			&:hover {
	
				background-color: white;
				border-color: $primary;
				text-decoration: none;
				color: $primary;			

			}

			@media screen and (min-width: 768px) {
			  
				line-height: 60px;

			}

		}

	}

}