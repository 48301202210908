@font-face {
	font-family: 'icomoon';
	src:url('../../fonts/icomoon.eot?baz3y5');
	src:url('../../fonts/icomoon.eot?#iefixbaz3y5') format('embedded-opentype'),
		url('../../fonts/icomoon.woff?baz3y5') format('woff'),
		url('../../fonts/icomoon.ttf?baz3y5') format('truetype'),
		url('../../fonts/icomoon.svg?baz3y5#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-collateral:before {
	content: "\e603";
}
.icon-csr:before {
	content: "\e604";
}
.icon-events:before {
	content: "\e605";
}
.icon-pencil:before {
	content: "\e606";
}
.icon-in:before {
	content: "\e600";
}
.icon-search:before {
	content: "\e601";
}
.icon-twitter:before {
	content: "\e602";
}
