.landing-page-content-section {
  margin: 15px 0;
  padding-block: 0;
  @media screen and (min-width: 992px) {
    margin: 60px 0;
  }

  .inner {
    @media (min-width: 985px) {
      padding-block: 0;
    }
  }

  h1 {
    font-size: 27px;
    color: #d61226;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    color: $tertiary;
  }
  p {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
  }

  .wp-video, img {
    margin-block: 30px;
  }

  &.media-centred {
    .wp-video, img {
      @media screen and (min-width: 992px) {
        max-width: 50%;
        width: 100%;
        height: auto;
        display: flex;
        
        &.aligncenter {
          margin-inline: auto;
        }

        &.alignright {
          margin-left: auto;
        }

        &.alignleft {
          margin-right: auto;
        }
      }
    }
  }

  &.media-full-width {
    .wp-video, img {
      width: 100%;
      height: auto;
    }
  }
}