.signposts {
  margin: 15px 0;
  @media screen and (min-width: 992px) {
    margin: 60px 0;
  }

  .inner {
    background-color: $secondary;
  }

  h2 {
    color: $primary;
    text-align: center;
    font-size: 24px;

    span {
      background-color: $secondary;
      position: relative;
      padding: 0 20px;
    }

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #FFF;
      left: 0;
      position: relative;
      display: block;
      top: 15px;
    }
  }
  h3 {
    font-size: 24px;
    color: $tertiary;
    padding-top: 15px;
  }
  img {
    max-width: 100%;
    width: 100%;
    transition: all .3s linear;
    &:hover {
      filter: blur(2px);
    }
  }
  .text {
    margin-top: 5px;
    p {
      font-size: 17px;
    }
  }
}
