$button-size : 2rem;
$transition: .2s;
$color: #e6173e;
$primary: #d61226;
$secondary: #ebebeb;
$tertiary: #23185d;

// Margin Utilities

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.landing-flexible {
	padding: 0 !important;
}

@mixin line {
  display: inline-block;
  width: $button-size;
  height: $button-size/7;
  background: $color;
  border-radius: $button-size/14;
  transition: $transition;
}

@mixin clearfix {

	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin transition($properties) {
	transition: $properties;
}

@mixin box-shadow($properties) {
	box-shadow: $properties;
}

@mixin opacity($properties) {
	opacity: $properties;
}


@-webkit-keyframes showslide {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@-moz-keyframes showslide {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes showslide {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
