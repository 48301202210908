.what-we-do {
  display: flex;
  margin-left: -2px;
  margin-right: -2px;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
  @media screen and (max-width: 991px) {
    max-width: 430px;
    margin: auto;
  }

  &__panel {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    flex: 1;
    margin: 0 2px;

    @media screen and (max-width: 991px) {
      margin-bottom: 4px;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 90px 30px;
      z-index: 2;

      .what-we-do__panel__icon {
        margin-bottom: 10px;
      }

      .what-we-do__panel__title {
        color: white;
        font-size: 50px;
        font-weight: 300;
        margin-bottom: 10px;
      }

      .what-we-do__panel__list {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;

        li {
          color: white;
        }
      }
    }

  }
}
