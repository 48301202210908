/*
Base mobile-first styles and small screens
*/

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: none;
}

body {
	background: #FFFFFF;
	font-family: 'Lato', 'Arial', sans-serif;
	font-weight: 400;
	font-size: 62.5%;
}

h1, h2, h3, h4, h5, h6 {
	color: #464646;
}

strong {

}

em, i {

}

a {
	color: #0a0a2c;
	text-decoration: none;
	font-weight: 700;

	&:hover {
		text-decoration: underline;
	}
}

.clearfix {
	clear: both;
}

.chromeframe {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}


#wrapper {
	background: #FFFFFF;
	min-width: 320px;
	min-height: 100%;
	position: absolute;
	width: 100%;
	z-index: 450;

	-webkit-transform: translate(0,0);
	transform: translate(0,0);
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;

	&.menu-open {
		-webkit-transform: translate(-250px,0);
		transform: translate(-250px,0);
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}
}


#main-header {
	border-bottom: 1px solid #d9d9d9;
	position: relative;

	.inner {
		padding: 25px 15px;
	}

	.logo {
		width: 203px;
		height: auto;
	}

	.lines-button {
	  display: inline-block;
	  padding: $button-size/2 $button-size/4;
	  -webkit-transition: .3s;
	  transition: .3s;
	  cursor: pointer;
	  user-select: none;
	  border-radius: $button-size/7;

	  &:hover {
		opacity: 1;
	  }

	  &:active {
		-webkit-transition: 0;
		transition: 0;
		background: rgba(0,0,0,.1);
	  }
	}

	.lines {

		//create middle line
	  @include line;
	  position: relative;

	  /*create the upper and lower lines as pseudo-elements of the middle line*/
	  &:before, &:after {

	   @include line;
		position: absolute;
		  left:0;
		content: '';
		-webkit-transform-origin: $button-size/14 center;
		transform-origin: $button-size/14 center;
	  }
	  &:before { top: $button-size/4; }
	  &:after { top: -$button-size/4; }
	}

	.lines-button.x2 .lines{
		-webkit-transition: background .3s .1s ease;
		transition: background .3s .1s ease;

		&:before, &:after{
		  //set transform origin back to center
		  -webkit-transform-origin: 50% 50%;
		  transform-origin: 50% 50%;
		  -webkit-transition: top .3s .2s ease, transform .3s ease;
		  transition: top .3s .2s ease, transform .3s ease;
		}
	}

	.lines-button.x2.close .lines{

		-webkit-transition: background .3s 0s ease;
		transition: background .3s 0s ease;
		background: transparent;

		&:before, &:after{
		  -webkit-transition: top .3s ease, transform .3s .3s ease;
		  transition: top .3s ease, transform .3s .3s ease;
		  top:0;
		  width: $button-size;
		}
		&:before{
		  -webkit-transform: rotate3d(0,0,1,45deg);
		  transform: rotate3d(0,0,1,45deg);
		}
		&:after{
		  -webkit-transform: rotate3d(0,0,1,-45deg);
		  transform: rotate3d(0,0,1,-45deg);
		}
	}

	#mobile-menu-button {
		background: transparent;
		border: none;
		position: absolute;
		top: 25px;
		right: 15px;

		&:active, &:focus {
			outline: none;
		}
	}
}

.menu-social {
	list-style-type: none;
	position: absolute;
	bottom: 0;
	right: 0;
	width: auto;

	li {
		display: block;
		float: left;
		width: 33%;

		a {
			color: #d12e3d;
			display: block;
			font-size: 1.4em;
			text-decoration: none;
			text-align: center;

			i {
				clear: both;
				display: block;
			}
		}
	}
}

#mobile-menu {
	background: #d12e3d;
	position: fixed;
	right: 0;
	width: 250px;
	height: 100%;
	z-index: 400;

	.menu {
		list-style-type: none;
		padding: 0 15px;

		li {

			a {
				border-bottom: 1px solid #ec516e;
				color: #FFFFFF;
				display: block;
				font-size: 2.1em;
				text-decoration: none;
				padding: 15px 0;
			}
		}

		.sub-menu {
			display: none;
		}
	}

	.menu-social {
		margin-top: 25px;
		position: static;
		width: 100%;

		li {

			a {
				color: #FFFFFF;
				font-size: 1.6em;

				i {
					font-size: 2.3em;
					margin-bottom: 10px;
				}
			}
		}
	}
}

#main-menu-container {
	display: none;
	position: relative;
}


#content {

	.inner {
		@include clearfix();
	}

	&.has-padding {
		padding: 15px;
	}

	&.page-not-found .inner{
		min-height: 500px;

		h1.main-title {
			font-size: 3.5em;
		}

	}

}


.page-content {

	h1 {
		color: #d61226;
		font-size: 2.7em;
		font-weight: 600;
		margin-bottom: 15px;
	}

	h2 {
		color: #23185d;
		font-size: 2.2em;
		font-weight: 600;
		margin-bottom: 15px;
	}

	p {
		color: #464646;
		font-size: 1.7em;
		line-height: 24px;
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul, ol {
		color: #464646;
		font-size: 17px;
		line-height: 24px;
		margin-bottom: 15px;
		padding-left: 30px;

		li {
			margin-bottom: 10px;
		}

		ul, ol {
			margin-top: 10px;
		}
	}

	ul { list-style-type: disc; }
	ol { list-style-type: decimal; }

	.alignnone, .alignleft, .aligncenter, .alignright {
		max-width: 100%;
		height: auto;
	}

	strong {
		font-weight: 600;
	}
}


.page-section {

	.inner {
		background-color: #ebebeb;
		background-repeat: no-repeat;
		background-position: 50% 60px;
		background-size: 280px auto;
		-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
		padding: 20px 15px;
		margin: 15px;
	}

	.section-feature-image {
		display: block;
		margin: 0 auto 25px auto;
		max-width: 400px;
		width: 100%;
		height: auto;
	}

	h1 {
		color: #d61226;
		font-size: 2.7em;
		font-weight: 600;
		margin-bottom: 25px;
	}

	h2 {
		color: #23185d;
		font-size: 2.2em;
		font-weight: 600;
		margin-bottom: 25px;
	}

	&.has-feature-image h1, &.has-feature-image h2 {
		height: auto;
	}

	p {
		color: #464646;
		font-size: 1.7em;
		line-height: 24px;
		margin-bottom: 25px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul, ol {
		border-left: 2px solid #a7a3be;
		color: #464646;
		font-size: 1.7em;
		line-height: 24px;
		padding: 0 0 0 30px;
		margin-bottom: 25px;
	}

	ul, ul li { list-style-type: disc; font-style: italic; }
}


.page-large-section {

	.inner {
		padding: 15px;
	}
}


.subpage-menu {
	border-top: 1px solid #ebebeb;
	list-style-type: none;

	li {

		a {
			background: url('../../img/arrow-right.png') no-repeat 15px 50%;
			background-size: 8px 12px;
			-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
			color: #898989;
			display: block;
			font-size: 1.7em;
			font-weight: 300;
			padding: 10px 15px 10px 35px;
		}

		&:first-child a { padding-top: 20px; background-position: 15px 65%; }
		&:last-child a { padding-bottom: 20px; background-position: 15px 37%; }

		&.current-menu-item a {
			color: #080b2a;
			font-weight: 600;
		}
	}
}

.subpage-menu-link {
	background: url('../../img/arrow-down.png') no-repeat 95% 50%;
	background-size: 13px 9px;
	-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
	border-bottom: 1px solid #d9d9d9;
	color: #464646;
	display: block;
	font-size: 1.7em;
	padding: 15px;
}

.subpage-menu-container-desktop {
	display: none;
}


.button {
	background: #d12e3d;
	border: none;
	border-radius: 5px;
	color: #FFFFFF;
	cursor: pointer;
	display: block;
	font-size: 1.6em;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	padding: 20px;
	margin-top: 25px;
	@include transition(background 0.2s linear);

	&:hover, &:focus, &:active {
		background-color: darken(#d12e3d, 10%);
		text-decoration: none;
		outline: none;
		@include transition(background 0.2s linear);
	}
}

.share {
	font-weight: 600;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


.home {

	#featured-images {
		display: table;
		margin-bottom: 25px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 320px;
		height: 275px;

		.inner {
			display: table-row;
		}

		.col1 {
			display: none;
		}

		.main-col {
			display: table-cell;
			font-size: 0;
			line-height: 0;
			position: relative;
			vertical-align: top;
			width: 100%;

			img {
				@include opacity(0);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: auto;
				max-height: 524px !important;
				z-index: 100;

				&.active {
					@include opacity(1);
					z-index: 200;
				}

				&.show {
					-webkit-animation: showslide 1.5s ease-in-out;
					-webkit-animation-fill-mode: forwards;
					-moz-animation: showslide 1.5s ease-in-out;
					-moz-animation-fill-mode: forwards;
					animation: showslide 1.5s ease-in-out;
					animation-fill-mode: forwards;

					z-index: 300;
				}
			}
		}

		.col2 {
			display: none;
		}
	}

	#consumer-video-wrapper {
		margin-top: 40px;

		.video-js {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}

	.page-content {

		h1 {
			font-size: 2.7em;
			line-height: 36px;
		}
	}
}


.page-template-about-us-php {

	#find-out-more {
		background: #bfc1d8;

		.inner {
			padding: 15px;
		}

		h2 {
			color: #FFFFFF;
			font-size: 2.7em;
			font-weight: 300;
			margin-bottom: 30px;
		}

		aside {
			border: 1px solid #e1e1e1;
			text-align: center;
			margin: 0 0 25px 0;
			padding: 15px;

			&:last-child {
				margin-bottom: 0;
			}

			h3 {
				color: #080b2a;
				font-size: 2.4em;
				font-weight: 300;
				margin: 0 0 20px 0;

				i {
					color: #717191;
					margin-right: 5px;
				}
			}

			p {
				color: #FFFFFF;
				font-size: 1.7em;
				font-weight: 300;
				line-height: 24px;
				margin: 0 0 20px 0;
			}
		}
	}
}


.page-template-leadership-team-php {

	#leadership-intro {

		.attachment-post-thumbnail {
			margin-top: 25px;
			max-width: 100%;
			height: auto;
		}

		blockquote {
			font-style: italic;

			cite {
				display: block;
				font-style: normal;
				font-weight: 600;
				text-align: right;
			}
		}
	}

	#leadership-people {
		margin-top: 30px;

		aside {
			margin-bottom: 30px;

			h2 {
				color: #23185d;
				font-size: 2.2em;
			}

			p {
				color: #464646;
			}
		}
	}

	.backers-heading {
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAACCAIAAAAfCIEKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowNDcxOTM3NC0wMmJmLTRjMmYtOTQwMy05OTkyMGQzZjRmZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTgxRTQwNUE2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTgxRTQwNTk2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxODE3NzBkZC1jZTExLTRiOTktOTYwZS1kNTE0ZTdhMmIwMWIiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1NWI5NTU5NS05OWE2LTExNzctOGZjYy1hZjkzYTQ1ZDQ3NDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5jBSweAAAAF0lEQVR42mI8f+EOAxJgYWJiROYDBBgASyQChkOKeO4AAAAASUVORK5CYII=) repeat-x 0 55%;
		background-size: auto 1px;
		-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
		margin-top: 25px;
		margin-bottom: 10px;

		.text {
			background: #FFFFFF;
			display: inline-block;
			padding-right: 20px;
		}
	}

	.page-section {


		.section-feature-image {
			border: 1px solid #e1e1e1;
		}
	}
}


.page-template-what-we-do-php {


	#areas-figure {
		margin: 25px 0 0 0;

		img {
			display: block;
			margin: 0 auto 5px auto;
			max-width: 100%;
			height: auto;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	#working-with-tiles {
		margin-top: 25px;

		.tile {
			@include clearfix();
			margin-bottom: 25px;

			&:last-child {
				margin-bottom: 0;
			}

			.tile-image {
				background-color: #d61226;
				background-repeat: no-repeat;
				background-position: 50% 30%;
				-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
				color: #FFFFFF;
				display: block;
				font-size: 2.3em;
				text-align: center;
				float: left;
				margin-right: 15px;
				padding-top: 90px;
				width: 130px;
				height: 130px;
			}

			p {
				color: #464646;
				font-size: 1.6em;
				line-height: 24px;
				margin: -5px 0 0 0;
			}
		}
	}
}


@media only screen and (max-width: 350px) {

	.page-template-what-we-do-php #working-with-tiles .tile p {
		float: left !important;
		width: 50% !important;
	}

}


.page-template-sectors-php {
		.page-section.section-1 {
			h2 {
				@media(max-width: 940px) {
					min-height: 150px;
				}
			}
		}
	.page-section.section-2 {
		h2 {
			@media(max-width: 940px) {
				min-height: 180px;
			}
		}
	}
	.page-section {
		h2 {
			@media(max-width: 940px) {
				height: 180px;
			}
		}
	}

	.sectors-chart {
		display: block;
		margin: 30px auto;
		max-width: 100%;
		height: auto;
	}
}


.page-template-careers-php {

	#careers-feature {
		@include clearfix();

		.inner {
			background: #ebebeb;
			margin: 15px;
			padding: 15px;
		}

		h2 {
			color: #d61226;
			font-size: 2.7em;
			font-weight: 600;
		}

		.flexslider {
			background: transparent;
			border: none;
			@include box-shadow(none);
			margin: 15px 0 0 0;
		}

		.flex-direction-nav a {
			color: rgba(255,255,255,0.8);
			text-shadow: none;
		}

		.flex-direction-nav .flex-prev {
			left: 20px;
		}

		.flex-direction-nav .flex-next {
			right: 20px;
		}
	}

	#gallery-content {

		#viewer {
			background: url('../../img/loading.gif') no-repeat 50% 50%;
			padding: 0 15px;
			margin-bottom: 15px;

			img {
				max-width: 100%;
				height: auto;
			}

			img, div {
				@include opacity(1);
				@include transition(opacity 0.3s ease-in-out);
			}

			img.hide, div.hide {
				@include opacity(0);
				@include transition(opacity 0.1s linear);
			}
		}

		.video-wrapper {
			position: relative;
			padding-bottom: 56.25%;
			height: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		h2 {
			color: #691c33;
			padding: 0 15px;
			margin: 0 0 10px 0;
		}
	}

	.thumbnail-strip {
		margin-bottom: 25px;
		position: relative;
		width: 100%;
		height: 85px;

		.inner {
			position: relative;
			width: 100%;
			height: 85px;
		}

		.fade-left, .fade-right {
			background-size: 30px auto;
			-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
			background-repeat: repeat-y;
			position: absolute;
			top: 0;
			width: 30px;
			height: 85px;
			z-index: 500;
		}

		.fade-left {
			background-image: url('../../img/fade-left.png');
			left: 0;
		}

		.fade-right {
			background-image: url('../../img/fade-right.png');
			right: 0;
		}

		.scroll-left, .scroll-right {
			display: none;
		}

		ul {
			@include clearfix();
			list-style-type: none;
			position: absolute;
			padding: 0 15px;
			z-index: 100;
			height: 85px;

			li {
				float: left;
				margin-right: 15px;
				margin-bottom: 15px;

				&:last-child {
					margin-right: 0;
				}

				&.active img {
					border: 2px solid #d12e3d;
				}

				img {
					border: 2px solid #ebebeb;
					@include transition(border 0.2s linear);
					width: 120px;
					height: auto;
				}
			}
		}
	}

	.overthrow-enabled .thumbnail-strip {
		position: relative;
		overflow: hidden;
		height: 85px;

		.inner {
			position: relative;
			overflow: auto;
			height: 85px;
		}

		ul {
			position: absolute;
			height: 85px;

			li {
				margin-bottom: 0;
			}
		}
	}

	.featured-testimonial {
		padding: 0 15px;

		.testimonial-picture {
			float: left;
			margin: 5px 15px 5px 0;
			max-width: 45%;
		}

		.testimonial {
			color: #898989;
			font-size: 2.2em;
			font-weight: 300;
			font-style: italic;
			line-height: 29px;

			cite {
				color: #464646;
				clear: both;
				display: block;
				font-style: normal;
				text-align: right;
				margin-top: 10px;
			}
		}
	}

	#careers-testimonials {

		.inner {
			padding: 15px;
		}

		.testimonial {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.testimonial-picture {
			margin: 0 0 15px 0;
		}

		.testimonial-detail {
			color: #898989;
			font-size: 1.7em;
			font-weight: 300;
			font-style: italic;
			line-height: 24px;

			cite {
				color: #464646;
				display: block;
				font-style: normal;
				margin-top: 10px;
			}
		}
	}
}


.single-job {

	#apply-form {
		background: #ebebeb;

		.inner {
			padding: 15px;
		}

		h2 {
			color: #464646;
			font-size: 3.1em;
			margin-bottom: 15px;
		}

		p {
			color: #898989;
			font-size: 1.7em;
			line-height: 24px;
			margin: 0 0 25px 0;
		}

		p.error {
			color: #f03546;
			font-size: 1.4em;
			margin-bottom: -23px;
		}

		#txt_attachment_wrapper p.error {
			margin-bottom: 0;
		}

		.field_wrapper.error input {
			border-color: #f03546;
		}

		.success {
			color: #31b509;
		}
	}
}


form {
	p.error {
		color: #f03546;
		font-size: 1.5em;
		margin: 8px 0 10px 0;
	}

	.req,
	.gfield_required {
		color: #d12f3e;
		cursor: help;
		font-size: 130%;
		padding: 0 5px;
	}

	li,
	.field_wrapper {
		margin: 0 0 15px 0;

		label {
			display: block;
			font-size: 1.7em;
			margin-bottom: 5px;
		}

		input:not([type=checkbox]):not([type=radio]), textarea {
			color: #464646;
			font-family: 'Lato', 'Arial', sans-serif;
			font-size: 1.5em;
			padding: 10px;
			width: 100%;

			&:hover, &:focus, &:active {
				border-color: darken(#a3a3a3, 60%);
				outline: none;
			}

			&[type='file'] {
				border: none;
			}
		}
	}

	.form_submit {
		background: #d12e3d;
		border: none;
		border-radius: 5px;
		color: #FFFFFF;
		display: block;
		font-family: 'Lato', 'Arial', sans-serif;
		font-size: 1.6em;
		text-decoration: none;
		text-align: center;
		text-transform: uppercase;
		padding: 10px 20px;
		margin-top: 25px;
		@include transition(background 0.2s linear);
		width: 100%;

		&:hover, &:focus, &:active {
			background-color: darken(#d12e3d, 10%);
			outline: none;
			text-decoration: none;
			@include transition(background 0.2s linear);
		}
	}
}

.success,
.gform_confirmation_message {
	color: #31b509;
	font-size: 17px;
	margin: 0;
}

.page-template-media-index-php {

	.page-section {
		-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
		background-position: 50% 0;

		.section-feature-image {
			margin-left: auto;
			margin-right: auto;
			max-height: 180px;
			max-width: 100%;
			width: auto;
		}
	}
}


.media-index-intro {
	margin-bottom: 15px;

	.inner {
		padding: 25px 15px;
	}

	h1 {
		color: #d61226;
		font-size: 2.7em;
		font-weight: 600;
		line-height: 32px;
	}

	p {
		color: #898989;
		font-size: 1.7em;
		line-height: 24px;
		margin-top: 15px;
	}

	.attachment-full {
		margin-top: 15px;
		max-width: 100%;
		height: auto;
	}
}

.in-the-media-intro {
	font-size: 0;
	text-align: center;

	display: block;
	margin-bottom: 20px;

	.inner {
		background: #ebebeb;
		padding: 30px 0;
	}

	.logo1 { width: 285px; }
	.logo2 { width: 160px; }
	.logo3 { width: 230px; }
	.logo4 { width: 265px; }
}

.tabs {
	border-bottom: 1px solid #ebebeb;
	@include clearfix();
	list-style-type: none;
	margin-top: 25px;

	li {
		float: left;

		a {
			border-bottom: 3px solid transparent;
			display: inline-block;
			color: #0a0a2c;
			font-size: 1.1em;
			margin-right: 10px;
			padding: 10px 0;

			a:hover {
				text-decoration: none;
			}
		}

		&:last-child a {
			margin-right: 0;
		}

		&.active a {
			border-bottom: 3px solid #e6173e;
			color: #464646;
		}
	}
}


.media-index-list {
	@include clearfix();

	> .inner {
		@include clearfix();
		max-width: 100%;
		width: 100%;
	}

	.media-post {

		.inner {
			border-bottom: 1px solid #ebebeb;
			margin: 0 15px;
			padding: 25px 0;
			max-width: 1280px;
		}

		.attachment-post-thumbnail, .attachment-large {
			border: 1px solid #e9e9e9;
			display: block;
			margin: 0 auto 25px auto;
			max-width: 100%;
			height: auto;
		}

		.post-title {
			font-size: 2.4em;
			line-height: 27px;
			margin-bottom: 10px;

			a {
				color: #e6173e;
			}
		}

		.post-date {
			color: #464646;
			display: block;
			font-size: 1.7em;
			font-style: italic;
			margin-bottom: 20px;
		}

		.post-excerpt {

			p {
				color: #898989;
				font-size: 1.7em;
				line-height: 24px;
			}

			.button {
				margin-top: 25px;
			}
		}

		&.press-release.first-post .inner, &.post.first-post .inner {
			background: #ebebeb;
			padding-left: 25px;
			padding-right: 25px;
		}
	}

	&.jobs-list {

		.inner {
			padding: 0 15px;
		}

		.job {
			border-bottom: 1px solid #ebebeb;
			margin-bottom: 15px;
			padding-bottom: 15px;
		}
	}

	.navigation {
		@include clearfix();
		text-align: center;
		margin-bottom: 25px;

		.wp-paginate {

			.current {
				color: #464646;
				background: transparent;
				border: none;
				font-size: 1.7em;
				font-weight: 600;
			}

			a {
				color: #23185d;
				background: transparent;
				border: none;
				font-size: 1.7em;

				&:hover {
					text-decoration: underline !important;
				}
			}
		}
	}
}


.media-nav {
	padding-top: 25px;
	padding-bottom: 25px;
}


.back-link {
	color: #e6173e;
	display: block;
	font-size: 2em;
	margin-top: 25px;
}


.media-post {

	.inner {
		@include clearfix();
		padding: 15px;
	}

	.meta {
		margin-bottom: 25px;
	}

	.post-title {
		color: #464646;
		font-size: 3.1em;
		line-height: 37px;
		margin-bottom: 10px;
	}

	.post-date {
		color: #464646;
		font-size: 1.7em;
		font-style: italic;
	}

	.attachment-post-thumbnail {
		border: 1px solid #e9e9e9;
		margin-bottom: 25px;
		max-width: 100%;
		height: auto;
	}

	form {
		background: #e9e9e9;
		border-radius: 5px;
		@include clearfix();
		margin-top: 30px;
		padding: 15px;

		> p {
			color: #898989;
			font-size: 1.7em;
			line-height: 24px;
			margin: 0 0 20px 0;
		}

		.submit_wrapper {

			input {
				border: none;
				font-weight: 600;
				width: 100%;
			}
		}
	}

	.success {
		background: #e9e9e9;
		border-radius: 5px;
		@include clearfix();
		margin-top: 30px;
		padding: 15px;
	}
}


.archive-search-link {
	color: #e6173e;
	display: block;
	font-size: 1.7em;
	text-align: center;
	vertical-align: middle;

	i {
		display: inline-block;
		font-size: 1.7em;
		margin: -5px 5px 0 0;
		vertical-align: middle;
	}

	&:hover {
		text-decoration: none;

		.text {
			text-decoration: underline;
		}
	}
}


.events-index {

	> .inner {
		padding: 15px;
	}

	.event {
		margin-bottom: 30px;

		.attachment-post-thumbnail {
			border: 1px solid #e9e9e9;
			margin-bottom: 10px;
			max-width: 100%;
			height: auto;
		}

		.event-title {
			font-size: 2.2em;
			margin-bottom: 10px;

			a {
				color: #23185d;
			}
		}

		.event-description {
			color: #464646;
			font-size: 1.8em;
			line-height: 24px;
			margin-bottom: 5px;

			span {
				display: block;
				font-weight: bold;
			}
		}
	}
}


.random-fact, .twitter-feed {
	margin: 0 15px 15px 15px;

	> .inner {
		background: #c0c1da;
		text-align: center;
		padding: 25px 15px;
	}

	h2 {
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAACCAIAAAAfCIEKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowNDcxOTM3NC0wMmJmLTRjMmYtOTQwMy05OTkyMGQzZjRmZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTgxRTQwNUE2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTgxRTQwNTk2MDI4MTFFNDlCOTVDQThBRDczRjg5NzciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxODE3NzBkZC1jZTExLTRiOTktOTYwZS1kNTE0ZTdhMmIwMWIiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo1NWI5NTU5NS05OWE2LTExNzctOGZjYy1hZjkzYTQ1ZDQ3NDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5jBSweAAAAF0lEQVR42mI8f+EOAxJgYWJiROYDBBgASyQChkOKeO4AAAAASUVORK5CYII=) repeat-x 0 50%;
		background-size: auto 1px;
		color: #23185d;
		font-size: 2.2em;
		font-weight: 600;
		margin: 0 auto 20px auto;

		> .inner {
			background: #bfc1d8 url('../../img/question-icon.png') no-repeat 10px 50%;
			background-size: 21px 21px;
			-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
			display: inline-block;
			margin: 0 auto 0 auto;
			padding: 0 10px 0 40px;
		}
	}

	ul {
		list-style-type: none;
	}

	p, li {
		color: #23185d;
		font-size: 1.7em;
		font-style: italic;
		line-height: 24px;
		padding: 0 20px;
	}
}


.random-testimonial {
	background: #bfc1d8;

	> .inner {
		text-align: center;
		padding: 25px 15px;
	}

	p {
		color: #FFFFFF;
		font-size: 1.7em;
		font-style: italic;
		line-height: 24px;
		padding: 0 20px;
	}

	cite {
		color: #464646;
		display: block;
		font-size: 1.7em;
		font-style: italic;
		text-align: right;
		line-height: 24px;
		padding: 0 20px;
		width: 100%;
	}
}


.page-template-search-php {

	.page-large-section h1 {
		margin: 0;
	}

	.search-filter-form {
		background: #ebebeb;

		.inner {
			padding: 15px;
		}

		fieldset {
			border: none;
			margin: 0 0 15px 0;

			legend {
				color: #464646;
				font-size: 1.6em;
				font-weight: 600;
				margin: 0 0 10px 0;
			}

			&#filter-wrapper {

				label {
					color: #464646;
					display: block;
					font-size: 1.5em;
					line-height: 18px;
					padding: 5px 0;
					vertical-align: middle;

					input {
						margin: -3px 5px 0 0;
						vertical-align: middle;
					}
				}
			}

			&#keywords-wrapper {

				label {
					color: #464646;
					display: block;
					font-size: 1.6em;
					font-weight: 600;
					margin: 0 0 10px 0;
				}

				input {
					background: #FFFFFF;
					border: 1px solid #a3a3a3;
					border-radius: 5px;
					color: #464646;
					font-family: 'Lato', 'Arial', sans-serif;
					font-size: 1.5em;
					padding: 5px;
					width: 100%;

					&:hover, &:focus, &:active {
						border-color: darken(#a3a3a3, 60%);
						outline: none;
					}
				}
			}

			&#date-wrapper {

				label {
					display: none;
				}

				select {
					background: #FFFFFF;
					border: 1px solid #a3a3a3;
					border-radius: 5px;
					color: #464646;
					font-family: 'Lato', 'Arial', sans-serif;
					font-size: 1.5em;
					float: left;
					padding: 5px;
					margin-right: 5px;
					width: 48%;

					&:hover, &:focus, &:active {
						border-color: darken(#a3a3a3, 60%);
						outline: none;
					}
				}

				#search-year {
					float: right;
					margin-right: 0;
				}
			}
		}

		.form-submit {

		}
	}

	.search-results {

		.inner {
			padding: 15px;
		}

		.noresults {
			color: #464646;
			font-size: 1.6em;
			font-weight: 600;
			text-align: center;
			padding: 20px 0;
		}

		.search-result {
			border-bottom: 1px solid #ebebeb;
			margin-bottom: 15px;
			padding-bottom: 15px;

			.attachment-post-thumbnail {
				border: 1px solid #e9e9e9;
				margin: 0 0 15px 0;
				max-width: 100%;
				height: auto;
			}

			h2 {
				font-size: 1.6em;
				font-weight: 600;
				margin: 0 0 10px 0;

				a {
					color: #d12e3d;
				}
			}

			.post-date {
				color: #464646;
				display: block;
				font-size: 1.5em;
				font-style: italic;
				margin-bottom: 10px;
			}

			.result-excerpt {

				p {
					color: #898989;
					font-size: 1.5em;
					line-height: 24px;
				}
			}
		}
	}

	.pagination {

		a {
			color: #d12e3d;
			display: block;
			font-size: 1.6em;
			text-align: center;
			padding: 10px 0;
		}
	}
}


#contact-form {

	.inner {
		background: #ebebeb;
		margin: 0 15px 15px 15px;
		padding: 15px;
	}

	.tabs {
		border-bottom-color: #898989;
		margin-top: 0;
	}

	.info {
		display: none;
		margin: 25px 0;

		&.active {
			display: block;
		}

		address, p {
			color: #464646;
			font-size: 1.7em;
			line-height: 24px;
			margin-bottom: 15px;

			.emph {
				color: #464646;
			}

			a {
				color: #e6173e;
			}
		}
	}

	.form-wrapper {

		h2 {
			color: #23185d;
			font-size: 2.2em;
			font-weight: 600;
			line-height: 28px;
			margin: 0 0 15px 0;
		}

		#txt_security_wrapper {
			@include clearfix();

			span {
				color: #464646;
				display: inline-block;
				font-size: 1.7em;
				float: left;
				padding-top: 10px;

				> span {
					color: #d12f3e;
					font-size: 130%;
					float: none;
					padding: 0 5px;
				}
			}

			input {
				float: left;
				margin-left: 15px;
				width: 80px;
			}

			p.error {
				clear: both;
				line-height: 22px;
				margin: 0;
				padding-top: 10px;
			}
		}
	}
}


.twitter-feed {


	h2 {
		padding: 0 10px;

		> .inner {
			background-image: url('../../img/twitter-icon.png');
			background-size: 29px 24px;
			-ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc');
			display: block;
			text-indent: -9999px;
			width: 29px;
		}
	}

	.tweet_time {
		clear: left;
		color: #dddeec;
		display: block;
		font-weight: 300;
		margin-top: 10px;
	}
}


#main-footer {
	background: #23185d;

	.inner {
		padding: 15px;

		p {
			color: #FFFFFF;
			font-size: 1.6em;
			font-weight: 300;
			line-height: 22px;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			&.copyright {
				font-size: 1.4em;
			}
		}
	}

	a {
		color: #FFFFFF;
	}

	.contact-info {
		margin-bottom: 15px;
	}

	.social-icons {
		@include clearfix();
		list-style-type: none;

		li {
			float: left;
			margin-right: 10px;

			&.email {

				a {
					display: inline-block;
					font-size: 12px;
					padding: 6px 0;
				}
			}

			img {
				width: 25px;
				height: 25px;
			}
		}
	}

	p.misc {
		font-size: 14px;
		margin-top: 15px;

		.credit {
		}
	}
}



.page-template-about-us-php .page-section.section-0 .inner { background: transparent; }
.page-template-about-us-php .page-section.section-2 { background-position: 100% 50px; h2 { height: 200px; } }
.page-template-about-us-php .page-section.section-2 { background-position: 50% 50px; }
.page-template-sectors-php .page-section.section-1 { -ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc'); h2 { height: 150px; } }
.page-template-sectors-php .page-section.section-2 { -ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc'); h2 { height: 180px; } }
.page-template-sectors-php .page-section.section-3 { -ms-behavior: url('/wp-content/themes/surfacegeneration/js/vendor/backgroundsize.min.htc'); h2 { height: 220px; } }

.lt-ie9 {
	.page-template-about-us-php .section-1 .section-feature-image { height: 307px; }
	.page-template-about-us-php #find-out-more aside.last { margin-right: 0; }
	.page-template-what-we-do-php #working-with-tiles .tile-2, .page-template-what-we-do-php #working-with-tiles .tile-5 { margin-right: 0; }
	.page-template-sectors-php .page-section.section-2 .section-feature-image { height: 438px; }
	.page-template-sectors-php .page-section.section-3 .section-feature-image { height: 421px; }
	.events-index .post-3, .events-index .post-7 { margin-right: 0; }
	.single-job #apply-form #txt_position_wrapper, .single-job #apply-form #txt_phone_wrapper { float: right; margin-right: 0; }
	#contact-form #txt_last_name_wrapper, #contact-form #txt_position_wrapper, #contact-form #txt_phone_wrapper { float: right; margin-right: 0; }
	.page-template-leadership-team-php #leadership-people aside.panel-3 { margin-right: 0; }
	.page-template-leadership-team-php .page-section .section-feature-image { height: 207px; }
	.page-template-what-we-do-php #areas-figure img { width: 32%; }
	.page-template-what-we-do-php #areas-figure img.last { margin-right: 0; }

	.page-section .section-feature-image { width: auto; }
}


.overthrow-enabled .thumbnail-strip {
	position: relative;
	overflow: hidden;
	height: 85px;

	.inner {
		position: relative;
		overflow: auto !important;
		height: 85px;
	}

	ul {
		position: absolute;
		height: 85px;

		li {
			margin-bottom: 0;
		}
	}
}


.news-updates-subscribe {
	margin: 0 15px 15px 15px;

	> .inner {
		background: #c0c1da;
		text-align: center;
		padding: 25px 20px;
        margin-bottom: 60px;
	}

	h2 {
		padding: 0 0 25px;
		background-size: auto 1px;
		color: #23185d;
		font-size: 2.2em;
		font-weight: 600;
		margin: 0;
	}

	p {
		color: #23185d;
		font-size: 1.7em;
		font-style: italic;
		line-height: 24px;
		padding: 0;
	}

   .widget_wysija_cont.php_wysija  {

        margin: 12px 0;
        padding: 0;
        font-family: 'Lato', 'Arial', sans-serif;
        font-size: 16px;

       form {

           padding: 0;
           margin: 0;
       }

       .allmsgs {

           li {

                font-family: 'Lato', 'Arial', sans-serif;
           }
       }
    }



    .wysija-paragraph {

        display: inline-block;
        position: relative;
        padding: 0;

        @media (max-width: 939px) {

            display: block;
        }

        label {
            display: none;
        }

        input[type="text"]{
            background-color: #e4e4e4;
            padding: 0 0 0 20px;
            border: 0 none;
            height: 32px;
            max-width: 90%;
            width: 280px;
            margin: 0;
            font-family: 'Lato', 'Arial', sans-serif;
            font-size: 16px;

            &:focus {
                outline: none;
            }

            @media (max-width: 1179px) {

                width: 200px;
            }

            @media (max-width: 939px) {

                width: 280px;
                display: block;
                margin: 0 auto 20px;
            }

        }

        /* Override default styles for MailPoet validation */
        .formError{

            top: 135% !important;
            left: 0% !important;

            .formErrorContent {
                width: 100%;
                padding: 10px !important;
            }
        }

    }

    .widget_wysija_cont .allmsgs .updated {

        font-family: 'Lato', 'Arial', sans-serif;
        font-size: 16px;
        padding: 10px 20px;
        display: inline-block !important;
    }

    .widget_wysija_cont .wysija-submit-field   {

        font-family: 'Lato', 'Arial', sans-serif;
        color: white;
        text-transform: uppercase;
        font-size: 16px;
        display: inline-block !important;
        background-color: #d12e3d;
        padding: 10px 25px;
        border: 0;
        border-radius: 5px;
        vertical-align: middle;
        margin: 0 10px;
        font-weight: bold;

        @media (max-width: 767px) {

            width: 100%;
            padding: 20px;
            margin: 0;
        }
    }
}

#subscribe-popup {

    position: fixed;
    right: 2%;
    top: 200px;
    z-index: 1001;

    #subscribe-popup-close {

        position: absolute;
        top: 0;
        right: 0;
    }
}
