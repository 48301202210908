.video-area-section {
  .video-area-video {
    max-width: 100%;
    width: 100%;
    height: auto;

    &:hover {
      cursor: pointer;
    }
  }

  video {
    &.video-area-video {
      display: flex;
    }
  }

  .video-wrapper {
    position: relative;

    &.playing iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      display: block;
    }

    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      display: none;
    }

    .iframe-poster {
      display: block;
      width: 100%;
      height: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.media-centred {
    .video-wrapper {
      padding-bottom: 56%;

      @media screen and (min-width: 992px) {
        padding-bottom: 28%;
      }
    }

    .video-area-video, img {
      @media screen and (min-width: 992px) {
        max-width: 50%;
        width: 100%;
        height: auto;
        justify-content: center;
        margin-inline: auto;
      }
    }
  }

  &.media-full-width {
    .video-area-video, img {
      width: 100%;
      height: auto;
    }
  }
}
