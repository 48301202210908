/*
Large tablets
*/


@media only screen and (min-width: 768px) {

	

	.button {
		display: inline-block;
		margin-top: 0;
		padding: 10px 25px;
	}
	
	.home {

		#featured-images {
			display: table;
			border-spacing: 3px;
			width: 100%;
			max-width: 100%;
			height: auto;

			.inner {
				display: table-row;
			}

			.col1 {
				display: table-cell;
				font-size: 0;
				line-height: 0;
				vertical-align: top;
				width: 25%;

				img {
					width: 100%;
					height: auto;
				}
			}

			.main-col {
				position: relative;
				width: 50%;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			.col2 {
				display: table-cell;
				font-size: 0;
				line-height: 0;
				vertical-align: top;
				width: 25%;

				img {
					width: 100%;
					height: auto;
				}
			}

			.wrapper {
				position: relative;
				margin-bottom: 3px;
				height: 33%;

				&:last-child {
					margin-bottom: 0;
				}

				img {
					z-index: 100;
				}

				.overlay {
					background: url('../../img/featured-hover-bg.png');
					background: rgba(97,98,129,0.8);
					position: absolute;
					overflow: hidden;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100%;
					max-height: 0;
					z-index: 200;

					@include transition(max-height 0.25s ease-out);

					a {
						color: #FFFFFF;
						display: block;
						font-size: 22px;
						line-height: 28px;
						font-weight: 300;
						position: absolute;
						top: 0;
						left: 0;
						padding: 20px;
						width: 100%;
						height: 100%;
						z-index: 250;

						&:hover {
							text-decoration: none;
						}
					}
				}

				&:hover .overlay {
					max-height: 200px;

					@include transition(max-height 0.3s ease-in-out);
				}
			}
		}

		.page-content {
			float: left;
			padding-right: 25px;
			width: 50%;
		}

		#consumer-video-wrapper {
			float: right;
			margin-top: 0;
			padding-left: 25px;
			padding-bottom: 28.2%;
			width: 50%;
		}
	}


	.page-template-about-us-php {

		#find-out-more {
			@include clearfix();

			aside {
				margin-right: 15px;
				float: left;
				width: 48.5%;

				&:nth-child(even) {
					float: right;
					margin-right: 0;
				}

				.button {
					width: 100%;
				}
			}
		}
	}


	.page-template-what-we-do-php { 

		#areas-figure {
			@include clearfix();

			img {
				float: left;
				padding: 0 2px;
				max-width: 33%;
			}
		}

		#working-with-tiles {
			@include clearfix();

			.tile {
				float: left;
				padding-right: 15px;
				width: 50%;

				&:nth-child(even) {
					padding-right: 0;
				}
			}
		}
	}


	.page-template-careers-php {

		#gallery-content {
			float: left;
			width: 50%;
			max-width: 620px;

			#viewer {
				text-align: center;
				margin-bottom: 15px;
				padding: 0 15px;

				img {
					max-width: 880px;
					width: 100%;
				}

				iframe {
					max-width: 880px;
				}
			}

			.heading-wrapper {
				margin: 0 auto;
				padding: 0 30px;
				max-width: 1050px;
			}

			h2 {
				font-size: 1.8em;
				margin: 0 auto 15px auto;
				padding: 0;
				width: 83.8%;
			}
		}

		.thumbnail-strip {
			margin: 0 auto 25px auto;
			padding: 0 15px;
			max-width: 1050px;
			height: 81px;

			.fade-left, .fade-right {
				display: none;
			}

			.scroll-left, .scroll-right {
				background-color: #e6173e;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: 9px 13px;
				display: block;
				position: absolute;
				top: 0;
				width: 5%;
				height: 100%;
				@include transition(background-color 0.2s linear);

				&:hover {
					background-color: darken(#e6173e, 10%);
					@include transition(background-color 0.2s linear);
				}
			}

			.scroll-left { background-image: url('../../img/scroll-left.png'); left: 15px; }
			.scroll-right { background-image: url('../../img/scroll-right.png'); right: 15px; }

			.inner {
				overflow: hidden !important;
				margin-left: auto;
				margin-right: auto;
				width: 85%;
			}

			ul {
				left: 0;
				padding: 0;
			}
		}

		.featured-testimonial {
			float: right;
			width: 50%;

			.testimonial-picture {
				margin-top: 0;
			}

			.testimonial {
				margin-top: -3px;
			}
		}

		#careers-testimonials {
			
			.testimonial {
				float: left;
				padding-right: 30px;
				width: 50%;
			}

			.testimonial-picture {
				float: left;
				margin: 0 15px 15px 0;
				max-width: 30%;
			}

			.testimonial-detail {
				font-size: 1.8em;
				line-height: 25px;
				float: right;
				max-width: 64%;
			}
		}
	}


	.media-index-intro {
	}

	.in-the-media-intro {
		display: block;
		margin-bottom: 20px;

		.inner {
			background: #ebebeb;
			padding: 60px 0;

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}

	.tabs {

		li {
			margin-right: 20px;

			a {	
				font-size: 1.8em;
			}
		}
	}


	.media-index-list {

		.media-post {

			.inner {
				@include clearfix();
			}

			.attachment-post-thumbnail, .attachment-large {
				float: left;
				margin: 0 25px 0 0;
				max-width: 36.5%;
			}

			.post-title, .post-date, .post-excerpt {
				clear: right;
				float: right;
				width: 60%;
			}			

			form {
				clear: right;
				float: right;
				position: relative;
				margin-top: 20px;
				padding-bottom: 0;
				width: 60%;

				.field_wrapper {
					float: left;
					width: 48%;

					&:nth-child(even) {
						float: right;
					}
				}

				.submit_wrapper {
					position: absolute;
					bottom: 15px;
					right: 15px;
					width: 35%;
				}
			}

			.success {
				clear: right;
				float: right;
				position: relative;
				margin-top: 20px;
				width: 60%;
			}
		}

		&.jobs-list {

			.inner {
				max-width: 1280px;
			}

			.media-post {

				.post-title, .post-date, .post-excerpt {
					float: none;
					width: 100%;
				}
			}
		}
	}


	.post-type-archive-press-release .media-index-list .media-post {

		.post-title, .post-date, .post-excerpt {
			float: none;
			width: 100%;
		}
	}


	.events-index {

		.event {
			
			.attachment-post-thumbnail, .attachment-large {
				margin-right: 30px;
			}
		}
	}


	.media-nav {
		@include clearfix();
		margin: 0 auto;
		padding: 0 15px;
		max-width: 1280px;
	}

	.navigation {
		float: left;
		padding-top: 26px;
		width: 100%;
	}

	.archive-search-link {
		float: right;
		margin: 25px 0;
	}


	.media-post {

		.meta {
			clear: both;
		}

		.attachment-post-thumbnail, .attachment-large {
			float: left; 
			max-width: 45%;
		}

		.content-wrapper {
			float: right;
			width: 50%;
		}

		&.job-post {

			.content-wrapper {
				float: none;
				width: 100%;
			}
		}
	}

	.single-press-release .media-post .content-wrapper {
		float: none;
		width: 100%;
	}

}


@media only screen and (min-width: 940px) {

	#main-header {

		.logo {
			width: 245px;
		}
	}

	.home #content.has-padding {
		padding: 0;

		.inner {
			padding: 30px;
		}
	}

	#mobile-menu, .subpage-menu-link, .subpage-menu {
		display: none;
	}

	.menu-social {
		bottom: 5px;

		li {
			width: auto;

			a {
				background: url('../../img/search-icon.png') no-repeat 100% 50%;
				padding-right: 30px;
				padding-top: 3px;
				width: auto;
				height: 20px;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.subpage-menu-container-desktop {
		background: #c0c1da;
		display: block;
		@include clearfix();

		ul {
			background: url('../../img/subpage-menu-icon.png') no-repeat 30px 50%;
			@include clearfix();
			margin: 0 auto;
			padding: 0 30px 0 70px;
			max-width: 1280px;

			li {
				display: block;
				float: left;

				a {
					color: #FFFFFF;
					display: block;
					font-size: 1.6em;
					font-weight: 300;
					padding: 20px;
				}

				&.current-menu-item a {
					color: #0a0a2c;
					font-weight: 600;
				}
			}
		}
	}

	#main-menu-container {
		display: block;

		.menu {
			list-style-type: none;
			position: absolute;
			left: 280px;
			bottom: -30px;

			li {
				float: left;
				position: relative;

				a {
					color: #313131;
					display: block;
					font-size: 1.4em;
					padding: 0 6px 35px 6px;
				}

				&.current-menu-item > a, &.current-page-ancestor > a {
					color: #d61226;
					font-weight: 600;
				}

				&.menu-item-has-children {
					background: url('../../img/dropdown-arrow.png') no-repeat 50% 25px;
				}

				.sub-menu {
					background: #FFFFFF;
					border: 1px solid #d9d9d9;
					border-bottom: none;
					display: none;
					position: absolute;
					width: 250px;
					z-index: 90000;

					li {
						border-bottom: 1px solid #d9d9d9;
						display: block;
						width: 100%;

						a {
							background: url('../../img/submenu-item.png') no-repeat 20px 50%;
							display: block;
							padding: 20px 20px 20px 45px;
						}
					}
				}

				&:hover .sub-menu {
					display: block;
				}
			}
		}
	}

	.single-post #main-menu-container .menu li.menu-item-36 > a,
	.post-type-archive-press-release #main-menu-container .menu li.menu-item-36 > a,
	.single-press-release #main-menu-container .menu li.menu-item-36 > a,
	.post-type-archive-in-media #main-menu-container .menu li.menu-item-36 > a,
	.single-in-media #main-menu-container .menu li.menu-item-36 > a,
	.post-type-archive-event #main-menu-container .menu li.menu-item-36 > a,
	.single-event #main-menu-container .menu li.menu-item-36 > a,
	.post-type-archive-collateral #main-menu-container .menu li.menu-item-36 > a,
	.single-collateral #main-menu-container .menu li.menu-item-36 > a,
	.post-type-archive-job #main-menu-container .menu li.menu-item-32 > a,
	.single-job #main-menu-container .menu li.menu-item-32 > a {
		color: #d61226;
		font-weight: 600;
	}

	.single-press-release .subpage-menu-container-desktop ul li.menu-item-147 a,
	.single-post .subpage-menu-container-desktop ul li.menu-item-154 a,
	.post-type-archive-collateral .subpage-menu-container-desktop ul li.menu-item-164 a,
	.post-type-archive-job .subpage-menu-container-desktop ul li.menu-item-217 a,
	.single-job .subpage-menu-container-desktop ul li.menu-item-217 a {
		color: #d61226;
		font-weight: 600;
	}

	.menu-item-364 a {
		color: #313131 !important;
	}

	.page-template-about-us-php .menu-item-364 a {
		color: #d61226 !important;
	}

	.blog .menu-item-369 a {
		color: #313131 !important;
	}


	#main-header {

		.inner {
			margin: 0 auto;
			padding: 30px;
		}

		#mobile-menu-button {
			display: none;
		}
	}


	.page-section {
		@include clearfix();

		.inner {
			background-size: 50% auto;
			background-position: 0 100%;
			@include clearfix();
			padding: 30px;
		}

		&.alt .inner {
			background-position: 100% 100%;
		}

		.section-content {
			clear: right;
			float: right;
			width: 48%;
		}

		&.alt .section-content { float: left; clear: left; }
		&.inverse .section-content { float: left; clear: left; }
		&.inverse.alt .section-content { float: right; clear: right; }

		h1, h2 {
			clear: right;
			float: right;
			margin-bottom: 20px;
			height: auto;
			width: 48%;
		}

		&.alt h1, &.alt h2 { float: left; }
		&.inverse h1, &.inverse h2 { float: left; }
		&.inverse.alt h1, &.inverse.alt h2 { float: right; }

		p, ul, ol {
			font-size: 1.8em;
			line-height: 25px;
		}

		.section-feature-image {
			clear: left;
			float: left;
			margin: 0;
			max-width: 46%;
		}

		&.alt .section-feature-image { float: right; }
		&.inverse .section-feature-image { float: right; }
		&.inverse.alt .section-feature-image { float: left; }
	}


	.page-large-section {

		.inner {
			padding: 30px;
		}
	}


	#content {
		padding: 0;

		&.page-not-found .inner{

			h1.main-title {
				font-size: 5em;
			}

		}	

	}


	.page-template-about-us-php {

		.section-2 .section-feature-image {
			margin-bottom: -60px;
		}

		#find-out-more {

			.inner {
				@include clearfix();
				padding: 40px 30px 15px 30px;
			}

			h2 {
				font-size: 4.7em;
			}		

			aside {
				margin-right: 30px;
				width: 22.4%;

				&:nth-child(even) {
					float: left;
					margin-right: 30px;
				}

				&:last-child {
					float: left;
					margin-right: 0;
				}
			}
		}
	}


	.page-template-leadership-team-php {

		#leadership-intro {
			h1 {
				margin-bottom: 35px;
			}

			.attachment-post-thumbnail, .attachment-large {
				float: right;
				margin-top: 0;
				max-width: 48%;
			}

			.content-wrapper {
				float: left;
				width: 48%;
			}
		}

		#leadership-people {
			@include clearfix();
			margin: 0 0 40px 0;

			aside {
				margin-bottom: 0;
				margin-right: 30px;
				float: left;
				width: 22%;

				&:last-child {
					margin-right: 0;
				}
			}
		}
		
		.page-section {

			h2 {
				clear: both;
				display: block;
				width: 100%;
			}
		}
	}


	.single-press-release .media-post .content-wrapper {
		float: none;
		width: 100%;
	}


	.page-template-what-we-do-php {

		#working-with-tiles {
			margin-top: 50px;

			/* .tile {
				padding-right: 0;
				margin-right: 70px;
				width: 28%;
				min-height: 260px;

				&:nth-child(even) {
					padding-right: 0;
				}

				&:nth-child(3n+3) {
					margin-right: 0;
				}

				p {
					float: right;
					width: 41%;
				}
			} */
		}
	}


	.page-template-sectors-php {


		.sectors-intro {
			float: left;
			width: 48%;
		}

		.sectors-chart {
			float: right;
			max-width: 48%;
		}
	}


	.page-template-careers-php {

		#careers-feature {
			padding: 30px 0;
		}

		#gallery-content {

			#viewer {
				padding: 0 30px;
			}

			.thumbnail-strip {
				padding: 0 30px;

				.scroll-left { left: 30px; }
				.scroll-right { right: 30px; }
			}
		}

		.featured-testimonial {
			padding: 0 30px;
		}

		#careers-testimonials {

			.inner {
				padding: 30px;
			}

			.testimonial-detail {
				max-width: 63%;
			}
		}
	}


	.single-job {

		#apply-form {
			@include clearfix();

			.inner {
				@include clearfix();
				padding: 30px;

				> h2, > p {
					clear: left;
					float: left;
					width: 45%;
				}

				.success {
					clear: none;
					float: right;
					margin-top: -40px;
					width: 50%;
				}
			}

			form {
				@include clearfix();
				float: right;
				margin-top: -40px;
				width: 50%;
			}

			h2 {
				font-size: 3.1em;
				margin-bottom: 15px;
			}

			p {
				font-size: 1.7em;
				line-height: 24px;
				margin: 0 0 25px 0;
			}

			.field_wrapper {
				float: left;
				margin: 0 0 30px 0;
				width: 47%;

				&:nth-child(2n+2) {
					float: right;
				}

				label {
					font-size: 1.7em;
					margin-bottom: 8px;
				}

				&#txt_attachment_wrapper {
					margin-bottom: 0;
				}
			}

			.form_submit {
				float: right;
				padding: 10px 30px;
				width: auto;
			}
		}
	}


	.page-template-media-index-php {

		.page-section {
			background-position: 0 50%;

			&.alt {
				background-position: 100% 50%;
			}

			.section-feature-image {
				width: auto;
				height: auto; 
				max-width: 50%;
				max-height: 100%;
			}
		}
	}


	.in-the-media-intro {

		.logo1 { width: 285px; }
		.logo2 { width: 160px; }
		.logo3 { width: 230px; }
		.logo4 { width: 265px; }
	}


	.media-index-list {

		.media-post {

			.inner {
				margin: 0 30px;
				padding: 30px 0;
			}

			.attachment-post-thumbnail, .attachment-large {
				max-width: 33.5%;
			}

			.post-title, .post-date, .post-excerpt {
				width: 63.5%;
			}			

			&.press-release.first-post, &.post.first-post {
				
				.attachment-post-thumbnail, .attachment-large {
					max-width: 46%;
				}

				.post-title, .post-date, .post-excerpt {
					width: 50%;
				}
			}

			form {
				border-radius: 10px;
				padding: 20px 20px 8px 20px;
				width: 63.5%;

				.submit_wrapper {
					bottom: 22px;
					right: 20px;
					width: 210px;
				}
			}

			.success {
				border-radius: 10px;
				padding: 20px;
				width: 63.5%;
			}
		}

		&.jobs-list {

			.inner {
				padding: 0 30px;
			}
		}
	}


	.post-type-archive-press-release .media-index-list .media-post {

		.post-title, .post-date, .post-excerpt {
			float: none;
			width: 100% !important;
		}
	}


	.media-index-intro {

		.inner {
			padding: 30px;
		}

		.attachment-full {
			margin-top: 30px;
		}
	}


	.events-index {

		> .inner {
			margin: 0 auto;
			padding: 0 30px;
			max-width: 1280px;
		}

		.event {
			
		}
	}


	.media-post {

		.inner {
			padding: 30px;
		}
	}


	.media-nav {
		padding: 0 30px;
	}

	.gf_name_has_2 {

		span {

			display: inline-block;
			width: 48%;

			label {

				display: block;
				margin-top: 7.5px;
				opacity: 0.5;

			}

			&:last-of-type {

				margin-left: 2%;

			}

		}

	}

	#contact-form {

		.inner {
			padding: 30px;
		}

		.tabs {
			margin-bottom: 30px;
		}

		.info {
			float: left;
			margin-top: 0;
			width: 45%;
		}

		.form-wrapper {
			float: right;
			width: 50%;
		}

		.field_wrapper {
			float: left;
			width: 45%;

			&:nth-child(2n+2) {
				float: right;
			}
		}

		#txt_query_wrapper {
			clear: both;
			float: none;
			width: 100%;
		}

		#txt_security_wrapper {
			margin-bottom: 0;
		}

		.form_submit {
			float: right;
			margin-top: -2px;
			width: 150px;
		}
	}

	.gfield {
	
		float: left;
		width: 100%;

		&.left { 

			float: left;
			width: 47%;

		}

		&.right { 

			float: right;
			width: 47%;

		}

	}

	.gform_footer {

		float: left;
		width: 100%;

	}

	.page-template-search-php {

	.page-large-section h1 {
		margin: 0;
	}

	.search-filter-form {

		.inner {
			padding: 30px;
		}

		fieldset {
			margin: 0 0 20px 0;

			legend {
				font-size: 1.8em;
				float: left;
				margin: 0;
				width: 115px;
			}

			&#filter-wrapper {

				label {
					display: inline-block;
					margin: 0 30px 0 0;
					padding: 0;

					input {
						margin: -3px 5px 0 0;
						vertical-align: middle;
					}
				}
			}

			&#keywords-wrapper {
				float: left;
				width: 50%;

				label {
					float: left;
					font-size: 1.8em;
					font-weight: 600;
					margin: 0;
					padding: 12px 0 0 0;
					width: 115px;
				}

				input {
					padding: 10px;
					width: 280px;
				}
			}

			&#date-wrapper {
				float: left;
				width: 37%;

				legend {
					padding: 12px 0 0 0;
					width: 70px;
				}

				select {
					padding: 10px;
					width: 120px;
				}

				#search-year {
					float: left;
					margin-right: 0;
				}
			}

			&#keywords-wrapper, &#date-wrapper {
				margin-bottom: 0;
			}
		}

		.form_submit {
			margin: 2px 0 0 0;
			float: right;
			width: auto;
		}
	}

	.search-results {

		.inner {
			padding: 30px;
			min-height: 300px;
		}

		.noresults {
			font-size: 2.4em;
			padding: 40px 0;
		}

		.search-result {
			border-bottom: 1px solid #ebebeb;
			margin-bottom: 30px;
			padding-bottom: 30px;
			@include clearfix();

			.attachment-post-thumbnail, .attachment-large {
				float: left;
				max-width: 30%;
			}

			h2 {
				font-size: 2.7em;
				font-weight: 600;
				float: right;
				margin: 0 0 10px 0;
				width: 68%;


				a {
					color: #d12e3d;
				}
			}

			.post-date {
				color: #464646;
				display: block;
				font-size: 1.8em;
				font-style: italic;
				margin-bottom: 20px;
				float: right;
				width: 68%;
			}

			.result-excerpt {
				float: right;
				width: 68%;

				p {
					color: #898989;
					font-size: 1.8em;
					line-height: 25px;
				}
			}
		}
	}

	.pagination {

		a {
			display: inline-block
		}

		.next {
			float: right;
		}

		.prev {
			float: left;
		}
	}
}


	#main-footer {

		.inner {
			@include clearfix();
			padding: 30px;
		}
	}


	.random-fact, .twitter-feed {

		> .inner {
			padding: 40px 60px;
		}

		h2 {

			> .inner {
				background-position: 20px 50%;
				padding: 0 20px 0 50px;
			}
		}
	}

	.twitter-feed h2 > .inner {
		padding: 0 20px;
		width: 59px;
	}


	.random-testimonial {

		> .inner {
			padding: 40px 60px;
		}
	}



	.page-template-about-us-php .page-section.section-0 { background-position: 100% 100%; background-size: 50% auto; }
	.page-template-about-us-php .page-section.section-2 { background-position: 100% 100%; background-size: 50% auto; h2 { height: auto; } }
	.page-template-sectors-php .page-section.section-1 { .inner { min-height: 380px; } h2 { height: auto; } }
	.page-template-sectors-php .page-section.section-2 { .inner { min-height: 380px; } h2 { height: auto; } }
	.page-template-sectors-php .page-section.section-3 { .inner { min-height: 455px; } h2 { height: auto; } }
	.page-template-media-index-php .page-section.section-0 .section-feature-image {  }
	.page-template-media-index-php .page-section.section-1 .section-feature-image {  }
	.page-template-media-index-php .page-section.section-2 .section-feature-image {  }
	.page-template-media-index-php .page-section.section-3 .section-feature-image { clear: none; }
	.page-template-media-index-php .page-section.section-4 .section-feature-image { clear: none; margin-left: 5%; }
	.page-template-media-index-php .page-section.section-5 .section-feature-image { clear: none; }
	
}