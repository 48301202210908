.content-area-section {
  margin: 15px 0;
  @media screen and (min-width: 992px) {
    margin: 60px 0;
  }
  .inner {
    background-color: $secondary;
  }
  h1 {
    font-size: 34px;
    color: $primary;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 24px;
    color: $tertiary;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 20px;
    color: $tertiary;
    margin-bottom: 20px;
  }
  p {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 25px;
  }
}
