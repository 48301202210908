.landing-page-table-image-area-section {
  margin: 15px 0;
  @media screen and (min-width: 992px) {
    margin: 60px 0;
  }

  .inner {
    &.has-white-background {
      background-color: white;
    }

    &.has-grey-background {
      background-color: #ebebeb;
    }

    .row {

      @media (max-width: 986px) {
        &.image-top {
          flex-direction: column-reverse!important;
        }

        &.text-top {
          flex-direction: column!important;
        }

        > div {
          &:first-of-type {
            order: 2;
          }
        }
      }


      &.row-reverse {
        @media (min-width: 985px) {
          flex-direction: row-reverse !important;
        }
      }

      &.image-centred {
        display: grid;
        flex-direction: column-reverse!important;
        justify-content: center;
        margin-inline: auto;
        max-width: 655px;

        h2 {
          margin-top: 25px;
        }

        p {
          margin-top: 25px;
        }

        > div {
          padding-inline: 0;
          margin-inline: auto;
          max-width: 100%;

          @media (min-width: 985px) {
            padding-inline: 15px;
          }

          &:first-of-type {
            order: 2;
          }
        }

        img {
          width: inherit;
          height: auto;

          @media (min-width: 985px) {
            width: auto;
          }
        }
      }
    }
  }

  h2 {
    font-size: 24px;
    color: $tertiary;
    margin-top: 30px;

    @media (min-width: 985px) {
      margin-top: 0
    }
  }

  p {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 25px;
    margin-top: 25px;

    @media (min-width: 985px) {
      margin-top: 0;
    }
  }

  img {
    width: 100%;
  }
}
