/*
Desktops
*/

@media only screen and (min-width: 1000px) {

	#main-menu-container {

		.menu {

			li {

				a {	
					font-size: 1.5em;
				}
			}
		}
	}


	.page-template-careers-php {

		#careers-feature {

			.inner {
				padding: 20px;
			}
		}
	}


	#main-footer {

		.contact-info {
			float: left;
			margin-bottom: 0;
		}

		.social-icons {
			float: right;

			li {
				margin-right: 0;
				margin-left: 15px;

				&.email {
					padding: 8px 0;
				}

				img {
					width: 35px;
					height: 35px;
				}
			}
		}

		.inner {
			position: relative;
		}

		p.misc {
			border-top: none;
			text-align: right;
			position: absolute;
			right: 30px;
			bottom: 30px;
			margin-top: 0;
			padding-top: 0;

			.credit {
				margin-top: 0;
			}
		}
	}

}


@media only screen and (min-width: 1140px) {

	#content {
		padding: 30px 0;
	}

	.inner {
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
		padding-right: 0;
		max-width: 1280px;
	}


	.menu-social {

		li {

			a {
				font-size: 1.6em;

				i {
					font-size: 2.2em;
				}
			}
		}
	}


	#main-menu-container {

		.menu {

			li {

				a {	
					font-size: 1.8em;
					padding: 0 15px 35px 15px;
				}
			}
		}
	}


	.page-content {

		h1, h2 {
			margin-bottom: 20px;
		}

		p {
			font-size: 1.8em;
			line-height: 25px;
			margin-bottom: 30px;
		}

		ul, ol {
			font-size: 18px;
			margin-bottom: 30px;

			ul, ol {
				margin-top: 10px;
			}
		}
	}


	.page-section {

		.inner {

		}
	}


	.page-large-section {

		.inner {
			padding: 30px;
		}
	}


	.home {

		#content.has-padding {
			padding: 20px 0;
		}

		.inner {
			padding: 30px;
		}

		#featured-images {
			margin-bottom: 50px;
			margin-left: auto;
			margin-right: auto;
			max-width: 1280px;
		}

		.page-content {

			h1 {
				
			}
		}
	}


	.page-template-what-we-do-php {

		#working-with-tiles {

			.tile {
				width: 28.9%;
				padding-right: 0;
				margin-right: 70px;
				min-height: 150px;

				&:nth-child(even) {
					padding-right: 0;
				}

				&:nth-child(3n+3) {
					margin-right: 0;
				}

				p {
					float: right;
					width: 53.5%;
				}
			}
		}
	}


	.page-template-sectors-php {

		
	}


	.page-template-careers-php {

		#gallery-content {

			.heading-wrapper {
				padding: 0;
			}
		}


		.thumbnail-strip {
			padding: 0;

			.scroll-left { left: 0; }
			.scroll-right { right: 0; }
		}
	}


	.media-index-list {

		.media-post {

			.inner {
				margin: 0 30px;
				padding: 30px 0;
			}

			.attachment-post-thumbnail, .attachment-large {
				max-width: 27.5%;
			}

			.post-title, .post-date, .post-excerpt {
				width: 70%;
			}

			form, .success {
				width: 70%;
			}
		}
	}


	.page-template-search-php {

		.search-filter-form {

			fieldset#keywords-wrapper, fieldset#date-wrapper {
				margin-bottom: 0;
			}

			fieldset#date-wrapper {
				width: 31%;
			}

			.form_submit {
				margin: 2px 0 0 0;
			}
		}
	}


	.events-index {

		.event {
			
		}
	}


	.random-fact, .twitter-feed {
		margin-bottom: 60px;

		> .inner {
			padding: 50px 120px;
		}
	}


	.random-testimonial {

		> .inner {
			padding: 50px 120px;
		}

		p, cite {
			font-size: 2em;
			line-height: 24px;
		}
	}


	.page-template-about-us-php .page-section.section-0 { background-position: 100% 100%; background-size: 50% auto; }
	.page-template-about-us-php .page-section.section-2 { background-position: 100% 100%; background-size: 50% auto; h2 { height: auto; } }
	.page-template-sectors-php .page-section.section-1 { background-size: 50% auto; background-position: 100% 100%; h2 { height: auto; } }
	.page-template-sectors-php .page-section.section-2 .section-feature-image { margin-bottom: 0; }

}


@media only screen and (min-width: 1250px) {

	.page-template-leadership-team-php {

		#leadership-people {

			aside {
				width: 23%;
			}
		}
	}

	.media-index-list {

		.media-post {

			.inner {
				margin: 0 auto 0 auto;
				padding: 30px 0;
				max-width: 1220px;
			}
		}
	}

	.events-index {

		.event {
			
		}
	}

	.page-template-about-us-php #find-out-more {

		aside {
			width: 23.1%;
		}
	}

}


@media only screen and (min-width: 1310px) {

	.page-section {

		.inner {
			margin: 30px auto;
		}
	}

	.page-template-careers-php {

		#careers-feature {

			.inner {
				margin: 0 auto;
				padding: 30px;
			}
		}
	}

	#contact-form {

		.inner {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.random-fact, .twitter-feed {
		margin: 0 auto 60px auto;
	}

	.in-the-media-intro {

		img {
			width: auto !important;
			height: auto !important;
		}
	}

	.page-template-media-index-php .page-section.section-0 .section-feature-image {  }
	.page-template-media-index-php .page-section.section-1 .section-feature-image {  }
	.page-template-media-index-php .page-section.section-2 .section-feature-image {  }
	.page-template-media-index-php .page-section.section-3 .section-feature-image { clear: none; margin-right: 5%; }
	.page-template-media-index-php .page-section.section-4 .section-feature-image { clear: none; margin-left: 10%; }
	.page-template-media-index-php .page-section.section-5 .section-feature-image { clear: none; margin-right: 5%; }

}


@media only screen and (min-width: 1500px) {

	.page-template-about-us-php .page-section.section-0 { background-position: 100% 100%; background-size:  auto; }
	.page-template-about-us-php .page-section.section-2 { background-position: 100% 100%; background-size: auto; h2 { height: auto; } }
	.page-template-sectors-php .page-section.section-1 { background-size: 50% auto; background-position: 100% 100%; h2 { height: auto; } }
	.page-template-sectors-php .page-section.section-2 .section-feature-image { margin-bottom: 0; }

}