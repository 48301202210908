.form-component {
  h1 {
    font-size: 34px;
    color: $primary;
  }
  h2 {
    font-size: 24px;
    color: $tertiary;
  }
  p {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .form {
    background-color: $primary;
    color: white;
    padding: 20px;

    &.negative-margin {
      @media screen and (min-width: 992px) {
        margin-top: -100px;
      }
    }

    h2 {
      font-size: 24px;
      color: white;
    }

    ul {
      list-style: none;

      li {
        margin: 0 0 20px 0;
      }
    }


    .hidden_label .gfield_label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      white-space: nowrap;
      border: 0;
    }

    input {
      border: none;
      border-radius: 0;
      padding: 15px;
    }

    input[type=text] {
      &:focus {
        outline: solid 4px $tertiary;
      }
    }

    select {
      width: 100%;
      height: 38.4px;
      padding: 12px;
      border: none;
      font-family: 'Lato', 'Arial', sans-serif;
      color: rgb(117, 117, 117);
      font-size: 1.5em;
    }

    .validation_message, .validation_error {
      color: white;
      font-size: 17px;
      margin-top: 5px;
    }

    .validation_error {
      margin-bottom: 10px;
    }

    .gform_confirmation_message, .gform_confirmation_message * {
      color: white;
    }

    .gform_download {
        list-style: initial;
        color: white;
        font-size: 16px;
        margin-left: 16px;
        li a {
          color: white;
        }
    }

    input[type=submit] {
      background-color: $tertiary;
      border: solid 2px $tertiary;
      font-weight: bold;
      font-size: 24px;
      padding: 20px 0;
      width: 100%;
      &:hover, &:focus {
        background-color: white;
        color: $tertiary;
      }
    }

    .gform_footer {
      float: unset;
    }
  }
}

.footer-form-section {

  @media screen and (min-width: 640px) {
    .gf_left_third, .gf_middle_third {
      padding-right: 10px;
    }
  }

  ul {
    list-style: none;

    li {
      margin: 0 0 20px 0;
    }
  }


  .hidden_label .gfield_label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  }

  input {
    border: none;
    border-radius: 0;
    padding: 15px;
    width: 100%;
  }

  input[type=text] {
    &:focus {
      outline: solid 4px $tertiary;
    }
  }

  select {
    height: 48.4px;
    padding: 12px;
    border: none;
    font-family: 'Lato', 'Arial', sans-serif;
    color: rgb(117, 117, 117);
    font-size: 1.5em;
    width: 100%;
  }

  .validation_message, .validation_error {
    color: white;
    font-size: 17px;
    margin-top: 5px;
  }

  .validation_error {
    margin-bottom: 10px;
    color: $primary;
  }

  .gform_confirmation_message, .gform_confirmation_message * {
    color: white;
  }

  .gform_footer {
    float: unset;
    display: flex;
    justify-content: flex-end;
  }

  input[type=submit] {
    background-color: $primary;
    border: solid 2px $primary;
    font-weight: bold;
    font-size: 22px;
    padding: 10px 20px;
    text-transform: unset;
    width: 100%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    @media screen and (min-width: 768px) {
      width: 25%;
    }
    &:hover, &:focus {
      background-color: white;
      color: $primary;
    }
  }

  textarea {
    height: 100px;
    border: 0;
    border-radius: 0;
    &:focus {
      outline: solid 4px $tertiary;
    }
  }
}


input[type=radio], input[type=checkbox] {
  label {
    color: white;
  }
}

.ginput_container_checkbox, .ginput_container_radio {
  label {
    display: inline-block;
    color: white;
  }
}


.gfield_checkbox, .gfield_radio {
  li {
    margin-bottom: -10px !important;
  }
}

.gfield_html {
  margin-bottom: 0 !important;
}
