.banner {
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media(min-width: 985px) {
    min-height: 400px;
  }
}
